<template>
    <div>
        <div class="inscriptionForm">
            <div class="partLeftInscriptionForm">
                <div class="inscriptionTitleLeft items-center">
                    <div class="greenLine w-10 mr-4"></div>
                    <h3>Inscription</h3>
                </div>
                <h4 class="text-7xl">
                    <span class="inline-block font-semibold">Faites <br> vous <br> livrer !</span>
                </h4>
                <p>Retrouvez nous à Paris, IDF, Lyon, Angers, Nantes ... et bientôt partout en France</p>
            </div>

            <div class="formPatient p-12 relative">
                <transition name="slide-fade" mode="out-in">
                    <div v-if="step === 1" key="step1">
                        <h2 class="text-3xl font-bold text-center">Nouveau chez mymediks ?</h2>
                        <p class="text-normal text-center">Vous êtes déjà inscrit ? <a class="text-primary font-bold text-green-1 cursor-pointer" :href="patientUrl + '/login'">Se connecter</a></p>
                        <div class="mt-5 mx-auto">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="form__input w-full">
                                    <form-input
                                        ref="first_name"
                                        name="first_name"
                                        placeholder="Prénom *"
                                        label="Prénom"
                                        v-model="user.first_name"
                                        @keyupEnter="nextStep"
                                    />
                                </div>
                                <div class="form__input w-full">
                                    <form-input
                                        ref="last_name"
                                        name="last_name"
                                        placeholder="Nom *"
                                        label="Nom"
                                        v-model="user.last_name"
                                        @keyupEnter="nextStep"
                                    />
                                </div>
                            </div>
                            <div class="form__input w-full">
                                <label class="text-sm">Date de naissance</label>
                                <FormDate 
                                    class="grid grid-cols-12 h-10 mt-1 text-sm rounded-md border-gray-200" 
                                    :max="yearNow"
                                    min="1900"
                                    v-model="user.birthday_date" 
                                    months-names="Janvier, Février, Mars, Avril, Mai, Juin, Juillet, Aout, Septembre, Octobre, Novembre, Décembre"
                                />
                            </div>
                            <div class="form__input w-full mt-5">
                                <form-input-phone
                                    :phone="user.phone"
                                    name="phone"
                                    label="Numéro de téléphone *"
                                    placeholder="Numéro de téléphone *"
                                    @input="getPhone"
                                />
                            </div>
                            <div class="form__input w-full">
                                <form-input
                                    ref="email"
                                    name="email"
                                    placeholder="Adresse mail *"
                                    label="Adresse mail"
                                    type="email"
                                    v-model="user.email"
                                    @keyupEnter="nextStep"
                                />
                            </div>
                            <div class="form__input">
                                <label class="text-sm">Sexe</label>
                                <div class="flex">
                                    <div class="bg-white h-10 p-2 text-center flex items-center border-2 border-gray-200 rounded-md overflow-hidden mr-4">
                                        <p-radio type="radio" class="p-default p-round p-smooth text-small -ml-1 mr-2" v-model="user.sexe_selected" id="women" color="success" value="Femme"/>
                                        <label for="women" class="px-3">F</label>
                                    </div>
                                    <div class="bg-white h-10 p-2 text-center flex items-center border-gray-200 border-2 rounded-md overflow-hidden">
                                        <p-radio type="radio" class="p-default p-round p-smooth text-small -ml-1 mr-2" v-model="user.sexe_selected" id="men" color="success" value="Homme"/>
                                        <label for="men" class="px-3">H</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row w-full mt-8">
                                <div class="flex justify-center">
                                    <button 
                                        @click="nextStep" 
                                        :disabled="!isFirstStepValid" 
                                        :class="isFirstStepValid ? 'bg-primary' : 'bg-gray-300'" 
                                        class="btn px-4 py-2 cursor-pointer  rounded-full text-white font-bold" >
                                            M'inscrire
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
       
                    <div v-else-if="step === 2" key="step2">
                        <check-password
                            :placeholder="'Mot de passe'"
                            @passwordCheck="passwordCheck"
                            v-model="user.password"
                            @keyupEnter="nextStep"
                        />

                        <div class="flex justify-between mt-8">
                            <button @click="step--" class="btn px-4 py-2 cursor-pointer btn--prev bg-gray-300 rounded-full text-white font-bold">Précédent</button>
                            <button @click="nextStep" :class="btnBackground(!user.isPasswordValid)" class="btn px-4 py-2 cursor-pointer rounded-full text-white font-bold" :disabled="!user.isPasswordValid">Continuer</button>
                        </div>
                    </div>
     
                    <div v-else-if="step === 3" key="step3">
                        <h2 class="text-2xl font-bold">Valider votre compte</h2>
                        <Message 
                            type="warning"
                            message="Pour terminer la création de votre compte, veuillez accepter les conditions suivantes."
                        />
                        <div class="row w-full">
                            <div class="flex items-center my-5">
                                <p-check type="checkbox" class="p-default p-round p-smooth text-small leading-none -ml-1 mr-2" v-model="conditions.general" name="conditions_g" color="success"/>
                                J'ai lu et j'accepte les conditions  générales d'utilisation de mymediks.
                            </div>
                            <div class="flex items-center my-5">
                                <p-check type="checkbox" class="p-default p-round p-smooth text-small -ml-1 mr-2" v-model="conditions.health_data" name="traitement_donne" color="success"/>
                                J'ai lu, j'ai compris et j'accepte le traitement de mes données de santé pour le service mymediks précisé dans la notice d'information et de consentement.
                            </div>
                            <div class="flex items-center my-5">
                                <p-check type="checkbox" class="p-default p-round p-smooth text-small -ml-1 mr-2" v-model="conditions.health_informations" name="article_info" color="success"/>
                                J'accepte de recevoir des articles et de l'information santé de la part de mymediks.
                            </div>

                            <div class="flex justify-between mt-8">
                                <div @click="step--" class="btn px-4 py-2 cursor-pointer btn--prev bg-gray-300 rounded-full text-white font-bold">Précédent</div>
                                <button @click="signUp" :class="btnBackground(isConditionsBtnDisable)" class="btn px-4 py-2 cursor-pointer rounded-full text-white font-bold" :disabled="isConditionsBtnDisable">
                                    <CircleSpinner v-if="isSignUpLoading" />
                                    <span v-else>Continuer</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import FormInput from '../tools/FormInput'
import FormInputPhone from '../tools/FormInputPhone'
import FormDate from '../tools/FormDate'
import CheckPassword from '../tools/CheckPassword'
import Message from '../tools/Message'

import CircleSpinner from '../tools/animation/CircleSpinner.vue'
import axiosConfig from '../../configs/axiosConfigs'

import {EventBus} from '../../main'


    export default {
        components: { FormInput, FormInputPhone, FormDate, CheckPassword, Message, CircleSpinner },
        computed: {
            yearNow() {
                return new Date().getFullYear().toString()
            },
            isConditionsBtnDisable() {
                return !this.conditions.general || !this.conditions.health_data
            },
            isFirstStepValid() {
                return this.user.first_name !== '' 
                    && this.user.last_name !== ''
                    && this.user.email !== ''
                    && this.user.phone !== ''
                    && this.user.birthday_date !== ''
            }
        },
        data() {
            return {
                step: 1,
                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    birthday_date: '',
                    password: '',
                    sexe_selected: 'Femme',
                    isPasswordValid: false,
                },
                conditions: {
                    general: false,
                    health_data: false,
                    health_informations: false,
                },
                patientUrl: process.env.VUE_APP_PATIENT_URL,
                isSignUpLoading: false
            }
        },
        methods: {
            redirect(url) {
            window.location = url
            },
            btnBackground(isDisable) {
                return isDisable ? 'bg-gray-300' : 'bg-primary'
            },
            nextStep() {
                this.step++
            },
            getPhone(newPhone) {
                this.user.phone = newPhone
            },
            passwordCheck(e) {
            this.user.isPasswordValid = e
            },
            signUp() {
                let inscriptionData = {
                    'birthday_date': this.user.birthday_date,
                    'email': this.user.email,
                    'password': this.user.password,
                    'password_confirmation': this.user.password,
                    'first_name': this.user.first_name,
                    'last_name': this.user.last_name,
                    'gender': this.user.sexe_selected,
                    'phone': this.user.phone,
                    'health_data': this.conditions.health_data,
                    'general': this.conditions.general,
                    'health_informations': this.conditions.health_informations
                }

                this.isSignUpLoading = true
            
                axiosConfig.post(process.env.VUE_APP_API_URL + 'users/create', 
                    inscriptionData, 
                    { headers: { 'User-Type': 'Patient'} }
                )
                .then(response => {
                    window.location = 
                        process.env.VUE_APP_PATIENT_URL + '/login?t=' + response.data.data.attributes.token + '&u=' + response.data.data.attributes.id
                })
                .catch( error => {
                    this.step = 1
                    setTimeout(() => {
                        EventBus.$emit('cleanErrors', this)
                        const status = error.response.status
                        if (status && status === 422) {
                            const errors = error.response.data.errors
                            EventBus.$emit('setErrors', this, errors)
                        }
                    }, 500);
                })
                .finally(() => {
                    this.isSignUpLoading = false
                })
            }
        }
    }
</script>

<style lang="scss">
    .inscriptionForm {
        font-family: "Montserrat",sans-serif;
        height: 100%;
        background-image: url("./../../assets/img/bottom_Plan de travail 1.svg");
        background-size: auto;
        background-repeat: no-repeat;
        background-position: bottom right;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 5%;
        padding: 5%;

        & .style-chooser {
            &.vs--open {
                border-color: #37e6a6 !important;
            }
            & .vs__dropdown-menu li:hover {
                color: #37e6a6;
                background-color: rgba(55, 230, 166, 0.1);
            }
        }

        
    }

    .partLeftInscriptionForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: flex-end;
        margin-bottom: 6rem;
    }

    .partLeftInscriptionForm h4 {
        display: block;
        padding-left: 10%;
        color: black;
        width: 70%;
        line-height: 75px;
        padding-top: 2rem;
    }

    .partLeftInscriptionForm p {
        font-size: 18px;
        width: 392px;
        padding-left: 10%;
        padding-top: 4rem;
    }

    .inscriptionTitleLeft {
        display: flex;
        flex-direction: row;
        color: #37e6a6;
        font-weight: bold;
        font-size: 1rem;
        padding-left: 10%;
        padding-top: 4rem;
    }

    .inscriptionTitleLeft img {
        padding-right: 1rem;
        width: 70px;
    }

    .formPatient {
        background-color: white;
        padding: 2rem;
        width: 43%;
        height: 80%;
        margin: 3rem;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
    }

    .patientFormTitle h5 {
        color: black;
        font-weight: bold;
        font-size: 2rem;
    }

    .patientFormTitle p {
        color: black;
        font-weight: 500;
    }

    .patientFormTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
    }

    .patientFormTitle a {
        color: #37e6a6;
        padding-left: 0.5rem;
    }

    .prenom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .nameInput {
        display: flex;
        flex-direction: column;
        width: 48%;
    }

    .patientName {
        display: flex;
        justify-content: space-between;
    }

    .nom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .formgroup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .datedenaissance {
        color: #b8b8b8;
        font-weight: bold;
        font-size: 1em;
        margin-top: 0.5rem;
    }

    .formcontrol {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 50%;
        color: #b8b8b8;
    }

    .Space {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .formInput {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 50%;
        color: #b8b8b8;
    }

    .formLabel {
        color: #b8b8b8;
        font-weight: bold;
        font-size: 1em;
        margin-top: 0.5rem;
    }

    .choice {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
        margin-right: 1rem;
        width: 20%;
        color: #b8b8b8;
        font-weight: bold;
    }

    .choice input:checked ~.checkmark {
        background-color: #37e6a6;
    }

    .genderSpace {
        display: flex;
        justify-content: flex-start;
    }

    .obligatoire {
        font-weight: bold;
        font-size: 0.75rem;
        color: #b8b8b8;
        text-align: right;
    }
    .patientSubmit {
        display: flex;
        justify-content: center;
    }

    .patientSubmitButton {
        text-align: center;
        font-weight: bold;
        color: #4A4848;
        background-color: #CDD0D9;
        border-radius: 30px;
        padding: 1rem;
        margin: 1rem;
        width: 30%;
    }

    .patientSubmitButton:hover {
        text-align: center;
        font-weight: bold;
        color: white;
        background-color: #37e6a6;
        border-radius: 30px;
    }

    .patientSubmitButton:after {
        outline: none;
        border: none;
    }

    .slide-fade-enter-active {
        transition: all 0.3s ease;
    }
    .slide-fade-leave-active {
        transition: all 0.3s ease;
    }
    .slide-fade-enter {
        transform: translateX(50px);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(-50px);
        opacity: 0;
    }

    @media screen and (max-width: 1200px) {    
    
    .inscriptionForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }
    
    .partLeftInscriptionForm {
        height: 100%;
        margin-bottom: 2rem;
        margin-top: -15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .formPatient {
        width: 80%;
        margin-top: 2rem;
    }
}
</style>