<template>
    <div>
        <div class="LivreurInscriptionForm">
            <div class="partLeftLivreurForm">
                <div class="livreurTitleLeft mb-6">
                    <img src="@/assets/img/orange-line.svg">
                    <h3>Inscription </h3>
                </div>
                <h4 class="text-5xl font-semibold">Devenez coursier spécialisé</h4>
                <p>Rejoignez notre communauté de coursiers spécialisés mymediks pour un service de livraison qui a du sens !</p>
            </div>
            <div class="formLivreur">
                <div class="filter">
                    <div class="livreurFormTitle">
                        <h5 class="text-3xl font-bold">Devenir coursier</h5>
                        <p class="text-sm font-light text-center">Notre équipe vous recontacte. Merci de remplir le formulaire</p>
                    </div>

                    <p class="mb-2">Informations générales</p>

                    <div class="flex justify-between">
                        <FormInput
                            ref="first_name"
                            v-model="form.first_name"
                            :name="'first_name'"
                            :placeholder="'Prénom *'"
                            class="w-full mr-2"
                            focusBorderColor="deliverer"
                        />
                        <FormInput
                            ref="last_name"
                            v-model="form.last_name"
                            :placeholder="'Nom *'"
                            :name="'last_name'"
                            class="w-full ml-2"
                            focusBorderColor="deliverer"
                        />
                    </div>

                    <FormInput
                        ref="email"
                        v-model="form.email"
                        :name="'email'"
                        :placeholder="'Adresse e-mail *'"
                        class="w-full mr-2"
                        focusBorderColor="deliverer"
                    />

                    <div class="flex justify-between">
                        <FormInput
                            ref="phone"
                            v-model="form.phone"
                            :name="'phone'"
                            placeholder="Numéro de téléphone *"
                            class="w-full mr-2"
                            focusBorderColor="deliverer"
                            :phoneFormatted="true"
                        />
                        <div class="w-full">
                            <v-select
                                v-model="form.nationality"
                                name="nationality"
                                id="nationality"
                                :options="nationalities"
                                :searchable="false"
                                :clearable="false"
                                placeholder="Nationalité *"
                                class="style-chooser"
                                style="height: 41px"
                            />
                            <small v-if="errors && errors.nationality" class="text-red-400 italic -mt-2">{{ errors.nationality[0] }}</small>
                        </div>
                    </div>

                    <p class="mb-2">Informations professionelles</p>

                    <div class="flex justify-between">
                        <ApiAddress
                            :name="'address'"
                            :placeholder="'Adresse postale (zone de livraison) *'"
                            :error="errors && errors.address ? errors.address[0] : ''"
                            focusBorderColor="deliverer"
                            @defineAddress="defineAddress"
                            class="w-2/3 mr-2"
                        />

                        <FormInput
                            ref="ray"
                            v-model="form.ray"
                            :name="'ray'"
                            placeholder="Rayon d’activité en km *"
                            class="mr-2 w-1/3"
                            focusBorderColor="deliverer"
                        />
                    </div>

                    <div class="flex justify-between mb-3">
                        <div class="w-full">
                            <v-select
                                v-model="form.vehicle_type"
                                name="travelMode"
                                id="vehicle_type"
                                :options="travelModes"
                                :searchable="false"
                                :clearable="false"
                                placeholder="Je me déplace en … *"
                                class="style-chooser mr-2 delivery-select"
                            />
                            <small v-if="errors && errors.vehicle_type" class="text-red-400 italic -mt-2">{{ errors.vehicle_type[0] }}</small>
                        </div>
                        <div class="w-full">
                            <v-select
                                v-model="form.experience"
                                name="experience"
                                id="experience"
                                :options="experiences"
                                :searchable="false"
                                :clearable="false"
                                placeholder="Années d’expérience *"
                                class="style-chooser ml-2 delivery-select"
                            />
                            <small v-if="errors && errors.experience" class="text-red-400 italic -mt-2">{{ errors.experience[0] }}</small>
                        </div>
                    </div>

                    <textarea 
                        v-model="form.presentation"
                        name="presentation" 
                        id="description" 
                        cols="30" 
                        rows="4" 
                        class="border-2 w-full rounded-md outline-none px-3 py-2 mb-3" 
                        :class="{'border-red-400' : errors.presentation }"
                        placeholder="Présentez vous en quelques phrases…*">
                    </textarea>
                    <div v-if="errors && errors.presentation" class="text-xs text-red-400 italic -mt-4">{{ errors.presentation[0] }}</div>

                    <div class="flex justify-between mb-6">
                        <div class="text-sm text-gray-400">* Champs obligatoires</div>

                        <div class="file-label text-sm cursor-pointe whitespace-nowrap overflow-ellipsis overflow-hidden">
                            <input @change="updateFile" class="inputfile" type="file" id="file">
                            <label class="cursor-pointer underline" for="file">{{ cvName || 'Ajouter un CV … *' }}</label>
                            <div v-if="errors && errors.cv" class="text-xs text-red-400 italic">{{ errors.cv[0] }}</div>
                        </div>
                        
                    </div>

                    <div class="flex justify-center">
                        <button
                            v-if="!isValid"
                            class=" font-bold text-white bg-gray-300 rounded-full px-8 py-2 mx-auto"
                            @click="submit"
                        >
                            Envoyer ma demande
                        </button>
                        <button
                            v-else
                            class="livreur-submir-btn font-bold text-white rounded-full px-8 py-2 mx-auto"
                            @click="submit"
                        >
                            Envoyer ma demande
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <transition> 
            <div v-if="displayDeliveryModal" style="background: #00000080; top: -300vh; left: -300vw; height: 700vh; width: 700vw;" class="fixed z-20 flex cursor-pointer" @mousedown="displayDeliveryModal = false" >
                <div class="bg-white mx-auto my-auto rounded-xl px-12 py-8 cursor-default relative" @mousedown.stop >
                    <div class="absolute top-3 right-5 cursor-pointer w-3 h-3" @click="displayDeliveryModal = false"><img src="@/assets/icons/times-solid.svg"></div>
                    <div class="text-lg font-medium text-center mb-4 mx-8">Votre candidature à bien été envoyé</div>
                    <div class="text-sm text-orange font-normal mx-8">Notre équipe vous recontacte très prochainement</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ApiAddress from "../tools/ApiAddress";
import axiosConfig from "../../configs/axiosConfigs";
import FormInput from "../tools/FormInput";

import { nationalities, travelModes } from '../../data/pharmacy'
import {EventBus} from "../../main";

    export default {
        components: {
            vSelect,
            ApiAddress,
            FormInput,
        },
        data() {
            return {
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    nationality: '',
                    address: '',
                    cp: '',
                    city: '',
                    ray: '',
                    vehicle_type: '',
                    experience: '',
                    presentation: '',
                    cv: null
                },
                cvName: null,
                travelModes: travelModes,
                experiences: ['1 à 2 ans', '3 à 5 ans', '+ de 5 ans'],
                nationalities: nationalities,
                errors: {},
                displayDeliveryModal: false
            }
        },
        methods: {
            setSelectError(errors) {
                if (errors.nationality) {
                    this.setBorderColorError('nationality')
                }
                if (errors.experience) {
                    this.setBorderColorError('experience')
                }
                if (errors.vehicle_type) {
                    this.setBorderColorError('vehicle_type')
                }
            },
            resetError() {
                this.errors = {}
                this.setBorderColorError('nationality', '#e5e7eb')
                this.setBorderColorError('experience', '#e5e7eb')
                this.setBorderColorError('vehicle_type', '#e5e7eb')
            },
            setBorderColorError(id, color = '#f87171') {
                document.querySelector(`#${id} .vs__dropdown-toggle`).style.borderColor = color
            },
            updateFile(e) {
                this.form.cv = e.target.files[0]
                this.cvName = e.target.files[0].name
            },
            defineAddress(e) {
                this.form.address = e.address
                this.form.cp = e.cp
                this.form.city = e.city
            },
            submit() {
                EventBus.$emit('cleanErrors', this)
                let formData = new FormData

                for (const attribute in this.form) {
                    formData.append(attribute, this.form[attribute])
                }

                this.resetError()

                axiosConfig.post('mail/recruitment', formData, {
                    headers: {
                        'Content-type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                        'User-Type': 'Patient',
                    }
                }).then(({status}) => {
                    if (status === 204) {
                        this.displayDeliveryModal = true
                    }
                }).catch( error => {
                    EventBus.$emit('cleanErrors', this)
                    const status = error.response.status
                    if (status && status === 422) {
                        const errors = error.response.data.errors
                        this.errors = errors
                        this.setSelectError(errors)
                        EventBus.$emit('setErrors', this, errors)
                    }
                })
            },
        },
        computed: {
            isValid() {
                let isValid = true

                for (const attr in this.form) {
                    let value = this.form[attr]
                    if (value === '' || value === null || typeof value === 'undefined') {
                        isValid = false
                    }
                }

                return isValid
            }
        }
    };
</script> 

<style lang="scss">
    .LivreurInscriptionForm {
        & .style-chooser {
            &.vs--open {
                border-color: #FA6B42 !important;
            }
            & .vs__dropdown-menu li:hover {
                color: #FA6B42;
                background-color: rgba(250, 107, 66, 0.1);
            }
        }
    }

 .text-orange {
     color: #ff6b43
 }
 .file-label {
    max-width: 200px;
 }
 .inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
 }
 .inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
 }
 .livreur-submir-btn {
     background-color: #FA6B42;
 }
 .LivreurInscriptionForm {
        height: 100%;
        background-image: url("./../../assets/img/bottom image-03.svg");
        background-size: auto;
        background-repeat: no-repeat;
        background-position: bottom right;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 5%;
    }

    .partLeftLivreurForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: flex-end;
        margin-bottom: 6rem;
        width: 40%;
    }

    .partLeftLivreurForm h4 {
        display: block;
        color: black;
        padding-left: 10%;
        padding-top: 4rem;
    }

    .partLeftLivreurForm p {
        color: #4A4848;
        font-size: 23px;
        padding-top: 4rem;
        width: 100%;
        padding-left: 10%;
        padding-top: 4rem;
    }

    .livreurTitleLeft {
        display: flex;
        flex-direction: row;
        color: #FA6B42;
        font-weight: bold;
        font-size: 1rem;
        padding-left: 10%;
        padding-top: 4rem;
    }

    .livreurTitleLeft img {
        padding-right: 1rem;
        width: 70px;
    }

    .formLivreur {
        background-color: white;
        padding: 2rem;
        margin: 3rem;
        width: 40%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
    }    
    
    .livreurFormTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
    }

    .livreurFormTitle h5 {
        color: black;
    }

    .livreurFormTitle p {
        color: black;
    }

    .livreurFormTitle a {
        color: #37e6a6;
        padding-left: 0.5rem;
    }

    .prenom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .nameInput {
        display: flex;
        flex-direction: column;
        width: 48%;
    }

    .livreurName {
        display: flex;
        justify-content: space-between;
    }

    .nom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .formgroup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .datedenaissance {
        color: #b8b8b8;
        font-weight: bold;
        font-size: 1em;
        margin-top: 0.5rem;
    }

    .formcontrol {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 50%;
        color: #b8b8b8;
    }

    .Space {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .formInput {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 50%;
        color: #b8b8b8;
    }

    .formLabel {
        color: #b8b8b8;
        font-weight: bold;
        font-size: 1em;
        margin-top: 0.5rem;
    }

    .choice {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
        margin-right: 1rem;
        width: 20%;
        color: #b8b8b8;
        font-weight: bold;
    }


    .obligatoire {
        font-weight: bold;
        font-size: 0.75rem;
        color: #b8b8b8;
        text-align: right;
    }
    .livreurSubmit {
        display: flex;
        justify-content: center;
    }

    .info {
        font-weight: bold;
        font-size: 1rem;
        color: #4A4848;
        text-align: center;
        margin-top: 1rem;
    }

    #description:focus {
        border-color: #FA7353;
    }

    @media screen and (max-width: 1200px) {    
    
    .LivreurInscriptionForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
    }
    
    .partLeftLivreurForm {
        height: 100%;
        margin-bottom: 2rem;
        margin-top: -15%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .formLivreur {
        width: 80%;
        margin-top: 2rem;
    }
}

</style>
