<template>
    <div>
        <div class="inscription">
            <div class="partLeftInscription">
                <div class="inscriptionTitle">
                    <img src="../../assets/img/yello-ligne.svg">
                    <h3>Inscription</h3>
                </div>
                <h4 class="text-5xl font-semibold"><span class="inline-block">Faites livrer vos patients <br> avec mymediks pro</span></h4>
                <p class="text-base w-10/12 mt-12">
                    Vous souhaitez mettre en place une solution de livraison de médicaments fiable et performante ?
                </p>

                <ul class="text-base w-10/12 list-disc mt-8">
                    <li>Visibilité digitale auprès d’un large réseau de patients</li>
                    <li>Réception de commandes de médicaments avec et sans ordonnance avec les plus hauts standards de sécurité et de confidentialité</li>
                    <li>Livraison urgente de vos patients par nos coursiers spécialisés</li>
                </ul>
            </div>
            <div class="form relative">
                <div class="filter">
                    <div class="pharmaFormTitle">
                        <h5 class="text-3xl">Vous êtes pharmacien ?</h5>
                        <p class="text-center">Un de nos experts vous recontacte. Merci de remplir le formulaire.</p>
                    </div>
                    <div class="pharmaName">
                        <div class="nameInput mr-2">
                            <form-input
                                ref="first_name"
                                name="first_name"
                                placeholder="Prénom"
                                label="Prénom *"
                                focusBorderColor="pharmacist"
                                v-model="user.first_name"
                                @keyupEnter="signUp"
                            />
                        </div>
                        <div class="nameInput">
                            <form-input
                                ref="last_name"
                                name="last_name"
                                placeholder="Nom"
                                label="Nom *"
                                focusBorderColor="pharmacist"
                                v-model="user.last_name"
                                @keyupEnter="signUp"
                            />
                        </div>
                    </div>
                    <div class="space">
                        <form-input-phone
                            :phone="user.phone"
                            name="phone"
                            label="Numéro de téléphone *"
                            placeholder="Numéro de téléphone"
                            focusBorderColor="pharmacist"
                            :error="errors && errors.phone ? errors.phone[0] : ''"
                            @input="getPhone"
                        />
                    </div>
                    
                    <div class="w-full">
                        <form-input
                            ref="email"
                            name="email"
                            placeholder="Adresse mail"
                            label="Adresse mail *"
                            type="email"
                            focusBorderColor="pharmacist"
                            v-model="user.email"
                            @keyupEnter="signUp"
                        />
                    </div>
                    <div class="w-3/4 mb-2 md:mb-4 xl:mb-6">
                        <label for="function" class="text-sm">Je suis *</label>
                        <v-select
                            v-model="user.function"
                            name="function"
                            id="function"
                            :options="functionOptions"
                            placeholder="Je suis …"
                            class="style-chooser w-full"
                        />
                        <small v-if="errors.function" class="text-red-400 italic flex">{{ errors.function[0] || '' }}</small>
                    </div>
                    
                    <div class="w-full">
                        <form-input
                            ref="name"
                            name="name"
                            placeholder="Nom de la pharmacie"
                            label="Nom de la pharmacie *"
                            focusBorderColor="pharmacist"
                            v-model="user.name"
                            @keyupEnter="signUp"
                        />
                    </div>
                    <div class="w-full">
                        <ApiAddress 
                            label="Adresse de la pharmacie *"
                            placeholder="Adresse de la pharmacie"
                            name="address"
                            :error="errors && errors.address ? errors.address[0] : ''"
                            focusBorderColor="pharmacist"
                            @defineAddress="setAddress"
                        />
                    </div>
                    <p class="obligatoire font-normal text-left mt-4">* Champs obligatoire</p>
                    <div class="pharmaSubmit">
                        <button 
                            class="pharmaSubmitButton font-bold px-8 py-3 text-white" 
                            :class="isFormFilled ? 'bg-yellow-400' : 'bg-gray-300'" 
                            type="submit" 
                            @click="signUp"
                        >
                            <CircleSpinner v-if="isSignUpLoading" />
                            <span v-else>M'inscrire</span>
                        </button>
                    </div>
                </div>
          </div>
        </div>

        <transition> 
            <div v-if="displayValidationModal" style="background: #00000080; top: -300vh; left: -300vw; height: 700vh; width: 700vw;" class="fixed z-20 flex cursor-pointer" @mousedown="displayValidationModal = false" >
                <div class="bg-white mx-auto my-auto rounded-xl px-12 py-8 cursor-default relative" @mousedown.stop >
                    <div class="text-lg font-medium text-center mb-4 mx-8">Merci pour votre prise de contact</div>
                    <div class="text-sm text-yellow-400 font-normal mx-8">Un conseiller vous rappelle très prochainement</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import FormInputPhone from '../tools/FormInputPhone'
import axiosConfig from '../../configs/axiosConfigs'
import FormInput from '../tools/FormInput'
import ApiAddress from '../tools/ApiAddress'

import {EventBus} from '../../main'
import { function_types } from '../../data/pharmacy'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import CircleSpinner from '../tools/animation/CircleSpinner.vue'


export default {
    components: {
        FormInputPhone,
        FormInput,
        ApiAddress,
        vSelect,
        CircleSpinner
    },
    computed: {
        isFormFilled() {
            let isFormFilled = true
            for (const key in this.user) {
                if (this.user[key] === '') isFormFilled = false
            }
            return isFormFilled
        }
    },
    data() {
        return {
            displayValidationModal: false,
            functionOptions: [],
            sended: false,
            user: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                name: '',
                address: '',
                function: ''
            },
            errors: {},
            isSignUpLoading: false
        }
    },
    methods: {
        setBorderColorError(id, color = '#f87171') {
            document.querySelector(`#${id} .vs__dropdown-toggle`).style.borderColor = color
        },
        setAddress(address) {
            this.user.address = address
        },
        getPhone(newPhone) {
            this.user.phone = newPhone
        },
        signUp() {
            if (this.sended) {
                this.displayValidationModal = true
                return false
            }
            this.isSignUpLoading = true
            this.setBorderColorError('function', '#e5e7eb')
            axiosConfig.post(process.env.VUE_APP_API_URL + 'contact',
                this.user, 
                { headers: { 'User-Type': 'Pharmacist'} }
            )
            .then(() => {
                EventBus.$emit('cleanErrors', this)
                this.errors = {}
                this.displayValidationModal = true
                this.sended = true
            })
            .catch( error => {
                this.step = 1
                EventBus.$emit('cleanErrors', this)
                const status = error.response.status
                if (status && status === 422) {
                    const errors = error.response.data.errors

                    this.errors = errors
                    if (errors.function) {
                        this.setBorderColorError('function')
                    }
                    EventBus.$emit('setErrors', this, errors)
                }
            })
            .finally(() => {
                this.isSignUpLoading = false
            })
        }
    },
    mounted() {
        this.functionOptions = function_types
    }
}
</script>

<style lang="scss">
    .inscription {
        font-family: "Montserrat", 'sans-serif';
        height: 100%;
        background-image: url("./../../assets/img/bottom image-02.svg");
        background-size: auto;
        background-repeat: no-repeat;
        background-position: bottom right;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 5%;
        padding: 5%;  

        & .style-chooser {
            &.vs--open {
                border-color: #FFC41E !important;
            }
            & .vs__dropdown-menu li:hover {
                color: #FFC41E;
                background-color: rgba(255, 196, 30, 0.1);
            }
        }
    }

    .partLeftInscription {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: flex-end;
        margin-bottom: 6rem;
    }

    .partLeftInscription h4 {
        display: block;
        padding-left: 10%;
        color: black;
        line-height: 55px;
        padding-top: 1.5rem;
    }

    .partLeftInscription p, .partLeftInscription ul {
        font-size: 18px;
        padding-left: 10%;
    }

    .inscriptionTitle {
        display: flex;
        flex-direction: row;
        color: #FCC029;
        font-weight: bold;
        font-size: 1rem;
        padding-left: 10%;
        padding-top: 4rem;
    }

    .inscriptionTitle img {
        padding-right: 1rem;
        width: 70px;
    }

    .form {
        background-color: white;
        padding: 2rem;
        height: 80%;
        margin: 3rem;
        width: 50%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 15px;

    }

    .pharmaFormTitle h5 {
        color: black;
        font-weight: bold;
    }

    .pharmaFormTitle p {
        color: black;
        font-weight: 500;
    }

    .pharmaFormTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
    }

    .pharmaFormTitle a {
        color: #37e6a6;
        padding-left: 0.5rem;
    }

    .prenom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .nameInput {
        display: flex;
        flex-direction: column;
        width: 48%;
    }

    .pharmaName {
        display: flex;
        justify-content: space-between;
    }

    .nom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .formgroup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .formcontrol {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 50%;
        color: #b8b8b8;
    }

    .space {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .formInput {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 50%;
        color: #b8b8b8;
    }

    .formLabel {
        color: #b8b8b8;
        font-weight: bold;
        font-size: 1em;
        margin-top: 0.5rem;
    }

    .choice {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
        margin-right: 1rem;
        width: 20%;
        color: #b8b8b8;
        font-weight: bold;
    }

    .choice input:checked ~.checkmark {
        background-color: #37e6a6;
    }

    .genderSpace {
        display: flex;
        justify-content: flex-start;
    }

    .obligatoire {
        font-size: 0.75rem;
        color: #b8b8b8;
    }
    .pharmaSubmit {
        display: flex;
        justify-content: center;
    }

    .pharmaSubmitButton {
        display: flex;
        justify-content: center;
        font-weight: bold;
        border-radius: 30px;
        margin: 1rem;
    }

    .pharmaSubmitButton:after {
        outline: none;
        border: none;
    }

    @media screen and (max-width: 1200px) {
    .inscription {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40%;
        padding: 10%;
        height: 100%;
    }

     .form {
        width: 90%;
    }

    .partLeftInscription {
        margin-top: -20vh;
    }

    .partLeftInscription h4 {
        display: block;
        color: black;
        font-weight: bold;
        font-size: 4rem;
        width: 100%;
        line-height: 5rem;
        padding-top: 2rem;
    }

    .partLeftInscription p {
        color: #4A4848;
        font-size: 23px;
        padding-top: 4rem;
        width: 100%;
    }
}

 @media screen and (max-width: 768px) {
    .inscription {
        margin-top: 20%;
    }

}
</style>

