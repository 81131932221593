<template>
	<div>
        <div class="flex h-screen top-0" style="margin: 0;">
            <div class="m-auto text-center max-w-6xl">
                <div class="font-bold text-6xl mb-3">
                    Oups ! <span class="text-primary"> Page introuvable </span>
                </div>
                <div class="text-xl">
                    Nous ne trouvons pas la page à laquelle vous souhaitez accéder.
                </div>
                <router-link to="/" tag="div" class="bg-primary text-white rounded-full py-2 px-4 w-max mx-auto mt-10 cursor-pointer">
                    Revenir à l'accueil
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "page404",
}   
</script>

<style scoped>

</style>