<template>
    <div class="w-full">
        <label v-if="label" :for="name" class="text-sm">{{
            label
        }}</label>
        <div class="flex">
            <div
                style="height: 41px;"
                class="mt-1 bg-white border-2 rounded-md block text-sm pl-2 pr-8 py-2 focus:outline-none focus:border-primary relative cursor-pointer"
                @click="toggleCountries"
                v-click-outside="closeCountries"
            >
                <img
                    class="h-5 w-auto mr-2"
                    :src="require(`@/assets/img/flags/${selected.img}`)"
                    alt=""
                />
                <span class="absolute right-2 top-3 text-gray-400">
                    <img :src="require('@/assets/icons/chevron-down-solid.svg')" alt="chevron down" :class="{'transform rotate-180' : openCountries}" class="w-3">
                </span>
                <transition name="dropdown">
                    <div
                        style="left: -2px; top: calc(100% - 2px);"
                        class="absolute top-full border-2 rounded-b-lg rounded-tr-lg bg-white z-10 w-24"
                        v-if="openCountries"
                        @click.stop
                    >
                        <div
                            v-for="(country, index) in countries"
                            :key="index"
                            class="p-2 flex hover:bg-gray-100"
                            :class="{'border-b-2': index !== countries.length - 1}"
                            @click="selectCountry(country)"
                        >
                            <img
                                class="h-5"
                                :src="require(`@/assets/img/flags/${country.img}`)"
                                alt=""
                            />
                            <span class="ml-4 absolute right-2">
                                {{ country.prefix }}
                            </span>
                        </div>
                    </div>
                </transition>
            </div>
            <form-input
                class="mt-1 ml-3 w-full"
                ref="telephone"
                :name="'telephone'"
                :placeholder="'Numéro de téléphone'"
                :focusBorderColor="focusBorderColor"
                :error="error"
                :phoneFormatted="true"
                v-model="inputPhone"
                @input="changePhone"
            />
        </div>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import FormInput from "./FormInput";

    export default {
        components: {
            FormInput,
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        props: {
            phone: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            },
            error: {
                type: String,
                default: ''
            },
            focusBorderColor: {
                type: String,
                default: 'patient'
            }
        },
        data() {
            return {
                newPhone: null,
                inputPhone: null,
                openCountries: false,
                countries: [
                    { name: "France", prefix: "+33", img: "france.png" },
                    { name: "Belgique", prefix: "+32", img: "belgique.png" },
                    { name: "Suisse", prefix: "+41", img: "suisse.jpg" },
                    { name: "Luxembourg", prefix: "+352", img: "luxembourg.png" },
                ],
                selected: { name: "France", prefix: "+33", img: "france.png" },
            };
        },
        mounted() {
            if (this.phone && this.phone.startsWith("+")) {
                this.countries.forEach(el => {
                    if (this.phone.startsWith(el.prefix)) {
                        this.selected = el
                        this.newPhone = "0" + this.phone.slice(el.prefix.length, this.phone.length + 1)
                        this.inputPhone = "0" + this.phone.slice(el.prefix.length, this.phone.length + 1)
                    }
                })
            } else {
                this.newPhone = this.phone
                this.inputPhone = this.phone
            }
        },
        methods: {
            toggleCountries() {
                this.openCountries = !this.openCountries;
            },
            closeCountries() {
                this.openCountries = false
            },
            selectCountry(country) {
                this.selected = country
                this.openCountries = false
                this.changePhone()
            },
            changePhone() {
                if (this.inputPhone.startsWith("0")) {
                    this.newPhone = this.selected.prefix + this.inputPhone.slice(1, this.inputPhone.length + 1)
                    this.$emit('input', this.newPhone.replace(/\s/g, ''))
                } else 
                if (this.inputPhone.startsWith("+")) {
                    this.newPhone = this.inputPhone
                    this.$emit('input', this.newPhone.replace(/\s/g, ''))
                } else {
                    this.newPhone = this.selected.prefix + this.inputPhone
                    this.$emit('input', this.newPhone.replace(/\s/g, ''))
                }
                    this.$emit('change')
            }
        },
    };
</script>

<style>

</style>
