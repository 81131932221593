import Vue from 'vue'
import VueRouter from 'vue-router'
import Patient from '../views/Patient.vue'
import Livreur from '../views/Livreur.vue'
import Pharmacien from '../views/Pharmacien.vue'
import Condition from '../views/Condition.vue'
import Contact from '../views/Contact.vue'
import Mentions from '../views/Mentions.vue'
import Cgu from '../views/Cgu.vue'
import Cookies from '../views/Cookies.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import page404 from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Patient',
    component: Patient,
  },
  {
    path: '/livreur',
    name: 'Livreur',
    component: Livreur,
  },
  {
    path: '/pharmacien',
    name: 'Pharmacien',
    component: Pharmacien,
  },
  {
    path: '/condition',
    name: 'Condition',
    component: Condition,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/mentions',
    name: 'Mentions',
    component: Mentions,
  },
  {
    path: '/cgu',
    name: 'Cgu',
    component: Cgu,
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: Cookies,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '*',
    name: 'page404',
    component: page404
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
