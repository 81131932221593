export const function_types = [
    'Pharmacien d\'officine titulaire',
    'Préparateur en officine',
    'Pharmacien d\'officine',
    'Pharmacien adjoint d\'officine',
    'Pharmacien d\'officine salarié',
    'Autre'
];

export const nationalities = [
    'Française',
    'Belge',
    'Suisse',
]

export const travelModes = [
    'Moto longue distance',
    'Scooter',
    'Vélo',
    'Vélo cargo',
    'Automobile',
    'Utilitaire',
    'Autre',
]