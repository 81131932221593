<template>
    <div class="contentPatient">
        <div id="pageTitle">
            <div class="titleTexte">
                <h1>
                    <span class="inline-block text-7xl leading-tight">Faites-vous livrer <br> vos médicaments<br> en 60 minutes</span>
                </h1>
                <h2>
                    <span class="text-xl inline-block w-1/2">
                        Sélectionnez la pharmacie partenaire de votre choix et commandez vos médicaments avec et sans ordonnance. <br> La livraison mymediks c’est simple, sécurisé et à partir de 7,90€.
                    </span>
                </h2>
                <button class="deliveryButton px-10 py-5 rounded-full cursor-pointer mt-12" target="_blank" @click="scrollMeDown('patientForm')">Me faire livrer</button>
            </div>

            <div class="arrowCircle">
             <a target="_blank" @click="scrollMe('toServices')"><img src="@/assets/img/down-arrow.svg"></a>
            </div>
        </div>

        <div class="services" ref="toServices">
            <div class="ml-0 servicetitle items-center mb-6">
                <div class="greenLine w-10 mr-4"></div>
                <h3>Découvrir nos services</h3>
            </div>
            <h4 class="text-5xl font-semibold">Comment ça marche ?</h4>
            <div class="fiches">
                <div class="description">
                    <img class="iconDescription mx-auto" src="../assets/img/02_Icones_illustrations_Plan de travail 1.svg">
                    <h5 class="h-32">Trouvez votre pharmacie</h5>
                    <p>Sélectionnez la pharmacie de votre choix parmi nos pharmacies partenaires à proximité de votre adresse</p>
                </div>

                <div class="description">
                    <img class="iconDescription mx-auto" src="../assets/img/02_Icones_illustrations_Plan de travail 1 copie.svg">
                    <h5 class="h-32">Passez commande</h5>
                    <p>Avec ou sans ordonnance, bénéficiez d’un conseil personnalisé avec votre pharmacien
                    </p>
                </div>

                <div class="description">
                    <img class="iconDescription  mx-auto" src="../assets/img/02_Icones_illustrations-03.svg">
                    <h5 class="h-32">Faites-vous livrer</h5>
                    <p>Vous êtes livré en moins de 60 minutes. Votre livraison est sécurisée et confidentielle</p>
                </div>
            </div>
        </div>

        <div class="partners">
            <div class="ml-0 servicetitle mb-6 items-center">
                <div class="greenLine w-10 mr-4"></div>
                <h3>Devenir partenaire</h3>
            </div>

            <h4 class="text-5xl font-semibold">Rejoignez le réseau mymediks</h4>
            <div class="roles">
                <div class="roleDescription">
                    <div class="pharmacist-container">
                        <img class="pharmacist-img" src="../assets/img/pharmacien.jpg" alt="pharmacien">
                    </div>
                    <div class="p-6 pt-0">
                        <h5>Pharmacie </h5>
                        <div class="roleText">
                            <p class="h-32">Devenez pharmacie partenaire mymediks et proposez un service de livraison à vos patients et clients</p>
                    
                            <a class="discoverButton" href="#" target="_blank" @click.prevent="goToPharma">Découvrir mymediks pro</a>
                        </div>   
                    </div>
                </div>

                <div class="roleDescription" id="livreur">
                    <div class="delivery-container">
                        <img class="delivery-img" src="../assets/img/livreur.jpg" alt="livreur">
                    </div>
                    <div class="p-6 pt-0">
                        <h5>Coursier</h5>
                        <div class="roleText">
                            <p class="text-right h-32">Rejoignez notre communauté de coursiers spécialisés mymediks pour un service de livraison qui a du sens !</p>
                            <a class="discoverButton2" href="" target="_blank" @click.prevent="goToDelivery">Devenir coursier</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="patientForm"> 
            <PatientForm/>
        </div>  
    </div>
</template>

<script>
    import PatientForm from "./../components/forms/Patient"
    export default {
        name: "Patient",
        components: {
            PatientForm,
        },
        mounted() {
            this.$parent.$data.currentView = "Patient"
        },
        methods: {
            goToPharma() {
                this.$router.push("/pharmacien")
            },
            goToDelivery() {
                this.$router.push("/livreur")
            },
            scrollMe(toServices) {
                var element = this.$refs[toServices];
                var top = element.offsetTop;

                window.scrollTo({
                    top: top - 100,
                    behavior: 'smooth',
                });
            },
            scrollMeDown(patientForm) {
                var element = this.$refs[patientForm];
                var top = element.offsetTop;                

                window.scrollTo({
                    top: top + 10,
                    behavior: 'smooth',
                });
            }
        },
    };
</script>

<style>   
    .greenLine {
        height: 3px;
        background-color: #37e6a6;
    }
    .contentPatient {
        font-family: "Montserrat", 'sans-serif';
    }
    .titleTexte {
        z-index: 1;
        margin-left: 125px;
        height: auto;
        margin-top: 25vh;
    }

    #pageTitle {
        position: relative;
        height: 100vh;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }    
    #pageTitle::before {
        z-index: 0;
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url("../assets/img/Fond patient.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right 55% top 20%;
        filter: brightness(0.6);
    }
    #pageTitle h1 {
        color: white;
        font-weight: bold;
        line-height: 5rem;
        font-size: 400%;
        margin-bottom: 3rem;
    }

    #pageTitle h2 {
        color: white;
        font-size: 23px;
    }

    .deliveryButton {
        font-weight: bold;
        color: white;
        background-color: #37e6a6;
    }

    .arrowCircle {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: auto;
    }

    .services {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 2rem;
        margin-top: 4rem;
        margin-bottom: 6rem;
        height: 80%;
    }

    .servicetitle img {
        width: 5%;
        height: auto;
        padding-right: 1rem;
    }

    .servicetitle {
        padding-left: 10%;
        display: flex;
        flex-direction: row;
        color: #37e6a6;
        font-weight: bold;
        font-size: 1rem;
        margin-left: 1rem;
    }

    .services h4 {
        padding-left: 10%;
        color: black;
    }

    .fiches {
        display: flex;
        justify-content: space-evenly;
        align-items: baseline;
    }

    .description {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 12%;
    }

    .description h5 {
        padding-top: 2rem;
        color: black;
        font-weight: 500;
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 3rem;
    }

    .description img {
        width: 80%;
        height: auto;;
    }

    .partners {
        background-color: #F5F6F6;
        min-height: 100vh;
        padding-top: 6rem;
    }

    .partners h4 {
        padding-left: 10%;
        color: black;
    }
     .roles {
        display: flex;
        justify-content: center;
        margin-bottom: 6rem;
    }

    .roleDescription h5 {
        color: black;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2rem;
        margin-left: 2rem;
        margin-right: auto;
        width: 80%;
    }

    .roleDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
        background-color: white;
        border-radius: 31px;
        margin: 2rem;
        margin-top: 4rem;
        
        width: 25%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); 
    }

    .pharmacist-img {
        border-radius: 31px 31px 0 0;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
    }

    .delivery-img {
        border-radius: 31px 31px 0 0;
        clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 100%);
    }

    .pharmacist-container {
        border-radius: 31px 31px 0 0;
        background-color: white;
    }

    .delivery-container {
        border-radius: 31px 31px 0 0;
        background-color: white;
    }

    #livreur h5 {
        text-align: right;
        margin-right: 2rem;
        margin-left: auto;
    }

    .roleText {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .roleText p {
        color: #4A4848;
        font-weight: 500;
        padding-top: 1rem;
        margin: 2rem;
    }

    .discoverButton {
        display: flex;
        justify-content: center;
        font-weight: bold;
        color:white;
        background-color: #CDD0D9;
        border-radius: 30px;
        padding: 1rem;
        margin: 1rem;
        width: 80%;
    }

    .discoverButton:hover {
        background-color: #FCC029;
    }

    .discoverButton2 {
        display: flex;
        justify-content: center;
        font-weight: bold;
        color: white;
        background-color: #CDD0D9;
        border-radius: 30px;
        padding: 1rem;
        margin: 1rem;
        width: 80%;
    }

    .discoverButton2:hover {
        background-color: #FA6B42;
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        .roleDescription {
            width: 30%;
        }
    }

    @media screen and (max-width: 1200px) {

    .titleTexte {
        margin-left: 3rem;
    }

    #pageTitle h1 {
        color: white;
        font-weight: bold;
        line-height: 4rem;
        font-size: 300%;
        margin-bottom: 3rem;
        width: 100%;
        height: auto;
    }

    .partners {
        background-color: #F5F6F6;
        height: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .partners h4 {
        padding-left: 5%;
    }

    .roles {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    .roleDescription {
        width: 50%;
    }

    #services {
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        .fiches {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }

        .description {
            width: 60%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 2rem;
        }

        .description img {
            width: 200%;
            height: auto;
        }
    }
}
</style>
