<template>
    <div class="pharmaContent">
        <div class="pharmaProTitle">
            <div class="layer">
                <h1>
                    <span class="inline-block text-7xl">Faites livrer <br> vos patients <br> en 60 minutes</span>
                </h1>
                <h2>
                    <span class="inline-block text-xl">
                        Mettez-vous en relation avec un large réseau de patients et recevez des commandes de médicaments avec et sans ordonnance. 
                        <br> Faites livrer vos patients par nos coursiers spécialisés avec les plus hauts standards de sécurité et de confidentialité
                    </span>
                </h2>
                <a class="tryButton cursor-pointer rounded-full text-white px-8 py-6 inline-block mt-12" target="_blank" @click="scrollMePlease('pharmaForm')">Essayer mymediks pro</a>
            </div>

            <div class="arrowCircle">
                <a target="_blank" @click="scrollMeTo('toBecomePartners')"><img src="@/assets/img/down-arrow.svg"></a>
            </div>   
        </div>

        <div class="becomePartners" ref="toBecomePartners">
            <div class="titleSpace ml-0 mb-6">
                <img src="../assets/img/yello-ligne.svg">
                <h2>Devenir partenaire</h2>
            </div>
            
            <h3 class="text-5xl font-semibold">Développez votre activité avec la plateforme mymediks</h3>
            <div class="reasons">
                <div class="argument mr-6">
                    <img src="../assets/img/02_Icones_illustrations-40.png" class="mx-auto">
                    <h4 class="text-center">Réseau de patients</h4>
                    <p>
                        Développez un nouveau réseau de patients et fidélisez le vôtre
                        <br><br> Attirez une nouvelle patientèle digitale
                        <br><br> Transformez vos patients en habitués
                        <br><br> Bénéficiez du réseau de patients mymediks et de ses partenaires
                    </p>
                </div>

                <div class="argument mr-6">
                    <img src="../assets/img/02_Icones_illustrations-41.png"  class="mx-auto">
                    <h4 class="text-center">Gain d’activité</h4>
                    <p>
                        Bénéficiez d'une plus grande visibilité digitale
                        <br><br> Multipliez vos canaux de vente
                        <br><br> Augmentez votre nombre de traitements d'ordonnances et de ventes de produits sans ordonnance
                        <br><br> Augmentez votre panier moyen grâce à la livraison
                    </p>
                </div>

                <div class="argument">
                    <img src="../assets/img/02_Icones_illustrations-42.png"  class="mx-auto">
                    <h4 class="text-center">Service sur-mesure</h4>
                    <p>
                        Démarquez-vous par un service innovant
                        <br><br> Proposez à vos patients un service de livraison en 60 minutes
                        <br><br> Dispensez le même conseil à vos patients qu’au comptoir et proposez des produits adaptés
                        <br><br> Faites appel à nos coursiers spécialisés pour vos livraisons
                    </p>
                </div>
            </div>
        </div>

        <div class="miseEnPlace">
            <div class="titleSpace ml-0 mb-6">
                <img src="../assets/img/yello-ligne.svg">
                <h2>Installer mymediks</h2>
            </div>
            <h3 class="text-5xl font-semibold">Mettez tout en œuvre pour réussir</h3>
            <p class="font-light text-lg">Nous vous accompagnons tout au long de l’installation de votre pharmacie sur la plateforme mymediks</p>
            <div class="allSteps">
                <div class="step">
                    <h5 class="text-center"><span class="inline-block">Une démo <br> gratuite</span></h5>
                    <img class="w-32" src="../assets/img/free-demo.png">
                    <p class="text-center font-light mt-4">Réalisée par nos experts</p>
                </div>

                <div class="step">
                    <h5 class="text-center"><span class="inline-block">Une installation <br> simple et rapide</span></h5>
                    <img class="w-32" src="../assets/img/simple-install.png">
                    <p class="text-center font-light mt-4">Une formation vidéo en ligne <br> Un service support à votre écoute</p>
                </div>

                <div class="step">
                    <h5 class="text-center"><span class="inline-block">Un kit de <br> démarrage</span></h5>
                    <img class="w-32" src="../assets/img/starting-kit.png">
                    <p class="text-center font-light mt-4">Des supports de communication <br> Des sacs scellés de livraison</p>
                </div>

                <div class="step">
                    <h5 class="text-center"><span class="inline-block">Une équipe dédiée <br> à votre service</span></h5>
                    <img class="w-32" src="../assets/img/team-support.png">
                    <p class="text-center font-light mt-4">Un suivi régulier pour optimiser votre activité</p>
                </div>
            </div>
        </div>

        <div class="discoverServices">
            <div class="titleSpace ml-0 mb-6">
                <img src="../assets/img/yello-ligne.svg">
                <h2>Découvrir notre fonctionnement </h2>
            </div>
            <h3 class="text-5xl font-semibold">Adaptez votre comptoir à l’ère du digital</h3>
            <p class="font-light text-lg">Simplifiez la gestion en ligne de vos commandes avec des outils simples et efficaces</p>
            <div class="process">
                <CourseOfDelivery class="processImg" />
             <img class="processImg-vertical" src="../assets/img/déroulement_livraison-vertical.png">
            </div>
        </div>
        <div ref="pharmaForm">
        <PharmaForm/>
        </div>
    </div>
</template>

<script>
    import PharmaForm from "./../components/forms/Pharmacien"
    import CourseOfDelivery from "./../components/svg/CourseOfDelivery"
    export default {
        name: "Pharma",
        components: {
            PharmaForm,
            CourseOfDelivery
        },
        mounted() {
            this.$parent.$data.currentView = "Pharmacien";
        },
        methods: {
            scrollMeTo(toBecomePartners) {
                var element = this.$refs[toBecomePartners];
                var top = element.offsetTop;

                window.scrollTo({
                    top: top - 120,
                    behavior: 'smooth',
                });
            },
            scrollMePlease(pharmaForm) {
                var element = this.$refs[pharmaForm];
                var top = element.offsetTop;                

                window.scrollTo({
                    top: top + 10,
                    behavior: 'smooth',
                });
            }
        },
    }; 
    
</script>

<style>
    .layer h1 {
        color: white;
        font-weight: bold;
        line-height: 5rem;
        font-size: 450%;
        margin-bottom: 2rem;
        margin-top: 5rem;
        width: 70%;
    }

    .layer h2 {
        color: white;
        font-size: 23px;
        width: 60%;
    }

    .pharmaProTitle {
        height: 100vh;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .pharmaProTitle::before {
        z-index: 0;
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url("../assets/img/Page vous êtes pharmacien.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right 55% top 20%;
        filter: brightness(0.6);
    }

    .layer {
        z-index: 1;
        width: 80%; 
        margin-left: 11rem;
        margin-top: 25vh;
    }

    .tryButton {
        font-weight: bold;
        color: #4A4848;
        background-color: #FCC029;
    }

    .arrowCircle {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: auto;
    }

    .becomePartners {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 4rem;
        margin-bottom: 6rem;
        height: 100%;
        padding: 0 10%;
    }

    .titleSpace img {
        width: 5%;
        height: auto;
        padding-right: 1rem;
    }

    .titleSpace {
        display: flex;
        flex-direction: row;
        color: #FCC029;
        font-weight: bold;
        font-size: 1rem;
        margin-left: 1rem;
    }

    .becomePartners h3 {
        color: black;
        margin-bottom: 3rem;
    }

    .reasons {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }

    .argument {
        width: 25%;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        padding: 2rem;
        height: auto;

    }

    .argument p {
        color: #4A4848;
    }

    .argument h4 {
        padding-top: 2rem;
        color: black;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        margin-top: -4rem;
    }

    .argument img {
        max-width: none;
    }

    .miseEnPlace {
        background-color: #F5F6F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 6rem;
        min-height: 100vh;
        padding: 0 10%;
    }

    .miseEnPlace h3 {
        color: black;
        margin-bottom: 2rem;
    }

    .allSteps {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

     .step {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
        width: 20%;
    }

    .step h5 {
        font-size: 1rem;
        font-weight: 400;
        padding: 1rem;
    }

    .discoverServices {
        height: 70vh;
        margin-top: 8em;
        padding: 0 10%
    }

    .discoverServices h3 {
        color: black;
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    .process {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .processImg {
        margin-top: 80px;
    }

    .processImg-vertical {
        display: none;
    }

    @media screen and (max-width: 1200px) {

     .layer h1 {
        color: white;
        font-weight: bold;
        line-height: 4rem;
        font-size: 400%;
        padding-right: 30%;
        margin-bottom: 3rem;
        width: 100%;
        height: auto;
    }

    .layer h2 {
        color: white;
        font-size: 23px;
        width: 70%;
    }

    .pharmaProTitle {
        margin-left: -10%;
    }

    .processImg {
        display: none;
    }

    .processImg-vertical {
        display: block;
        padding: 2%;
        width: 70%;
        height: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .becomePartners {
        padding: 0 10%;
    }

    .reasons {
        display: flex;
        justify-content: center;
        align-items: stretch;
        padding: 0.5rem;
    }

    .argument {
        width: 26%;
        margin: 1.5em;
    }

    .argument p {
        text-align: justify;
    }

    .miseEnPlace {
        background-color: #F5F6F6;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1rem;
        height: 70vh;
    }
    
    @media screen and (max-width: 768px) {
    .pharmaProTitle {
        height: 92vh;
    }
    .layer {
        width: 80%; 
        margin-left: 11rem;
        margin-top: 15vh;
    }

    .layer h1 {
        color: white;
        font-weight: bold;
        line-height: 4rem;
        font-size: 400%;
        padding-right: 30%;
        margin-bottom: 3rem;
        width: 100%;
        height: auto;
    }

    .layer h2 {
        color: white;
        font-size: 26px;
        width: 80%;
    }

    .pharmaProTitle {
        margin-left: -10%;
        margin-top: -2vh;
    }

    .reasons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }

    .argument {
        width: 40%;
        margin: 1.5em;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        padding: 2rem;
        height: auto;
    }

    .argument p {
        text-align: justify;
    }

    .processImg-vertical {
        display: block;
        padding: 10%;
        width: auto;
        height: 90%;
        margin-bottom: 16em;
        margin-top: 5em;
    }    
    
    .process {
        height: 100%;
   
    }
  }
}
</style>

