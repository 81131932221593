<template>
    <div class="formContact">
        <div class="formTitle">
        </div>

        <div class="flex items-center contact-title p10 mt-24 mb-6">
            <div class="border-2 border-green-1 mr-2"></div>
            <h3>Contact</h3>
        </div>

        <h4 class="text-5xl font-semibold p10"> Contactez-nous ! </h4>

        <form action="/action_page.php" method="get" id="form4" class="formInputs">
            <div class="name flex">
                <div class="nameInput mr-2">
                    <FormInput
                        label="Prénom *"
                        ref="first_name"
                        v-model="form.first_name"
                        name="first_name"
                        placeholder="Prénom"
                        class="w-full"
                    />
                </div>

                <div class="nameInput ml-2">
                    <FormInput
                        label="Nom *"
                        ref="last_name"
                        v-model="form.last_name"
                        name="last_name"
                        placeholder="Nom"
                        class="w-full"
                    />
                </div>
            </div>

            <div class="nameInput">
                <FormInput
                    label="Adresse mail *"
                    ref="email"
                    v-model="form.email"
                    name="email"
                    placeholder="Adresse mail"
                    class="w-full"
                />
            </div>

            <div class="nameInput">
                <label for="text" class="mb-1">Votre message :</label>
                <textarea 
                    v-model="form.message" 
                    name="write" id="write" 
                    cols="30" rows="5" 
                    placeholder="Ecrivez nous ici..." 
                    maxlength="5000"
                    class="write rounded-md p-2 focus:outline-none"
                    :class="{'border-red-400' : errors && errors.message}"
                >
                </textarea>
                <div v-if="errors && errors.message" class=" text-xs text-red-400 italic">{{ errors.message[0] }}</div>
            </div>
        </form>
        <div class="submit-button">
            <button class="submit" :class="isFormValid ? 'bg-primary' : 'bg-gray-300'" @click="sendContactForm">
                <CircleSpinner v-if="isContactFormLoading" class="mx-auto" />
                <span v-else>Envoyer</span>
            </button>
        </div>

        <transition> 
            <div v-if="displayContactModal" style="background: #00000080; top: -300vh; left: -300vw; height: 700vh; width: 700vw;" class="fixed z-20 flex cursor-pointer" @mousedown="displayContactModal = false" >
                <div class="bg-white mx-auto my-auto rounded-xl px-12 py-8 cursor-default relative" @mousedown.stop >
                    <div class="absolute top-3 right-5 cursor-pointer w-3 h-3" @click="displayContactModal = false"><img src="@/assets/icons/times-solid.svg"></div>
                    <div class="text-lg font-medium text-center mb-4 mx-8">Votre message à bien été envoyé</div>
                    <div class="text-sm font-normal mx-8" style="color: #37e6a6">Notre équipe vous recontacte très prochainement</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axiosConfig from '../../configs/axiosConfigs';
import CircleSpinner from '../tools/animation/CircleSpinner'
import FormInput from "../tools/FormInput";
import {EventBus} from "../../main";

    export default {
        name: 'Contact',
        components: { FormInput, CircleSpinner },
        computed: {
            isFormValid() {
                let valid = true
                for (const key in this.form) {
                    if (!this.form[key]) valid = false
                }
                return valid
            }
        },
        data() {
            return {
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    message: ''
                },
                errors: {},
                isContactFormLoading: false,
                displayContactModal: false
            }
        },
        methods: {
            sendContactForm() {
                EventBus.$emit('cleanErrors', this)
                this.errors = {}
                this.isContactFormLoading = true

                axiosConfig.post('mail/contact', this.form, { headers: { 'user-type': 'Patient' } })
                .then(() => {
                    this.displayContactModal = true
                })
                .catch((error) => {
                    EventBus.$emit('cleanErrors', this)
                    const status = error.response.status
                    if (status && status === 422) {
                        const errors = error.response.data.errors
                        this.errors = errors
                        EventBus.$emit('setErrors', this, errors)
                    }
                })
                .finally(() => {
                    this.isContactFormLoading = false
                })
            }
        }
    };
</script>

<style>
    .write {
        border: 2px solid #DBDDE4;

    }
    .write:focus {
        border-color: #37e6a6;
    }
    .p10 {
        margin: 0 10%;
    }
    .contact-title {
        color: #37e6a6;
    }
    .contact-title div {
        width: 50px;
        min-width: 50px;
        border-color: #37e6a6;
    }

    .formInputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 4rem;
    }

    .formContact {
        font-family: "Montserrat";
        background-color: #F5F6F6;
    }

    .formTitle {
        padding-left: 10%;
        display: flex;
        flex-direction: row;
    }

    .formTitle img {
        width: 5%;
        height: auto;
        padding-right: 1rem;
    }

    .formTitle h5 {
        color: #37e6a6;
        font-weight: bold;
        font-size: 1.5rem;
        margin-left: 1rem;
    }

    .formContact p {
        font-weight: bold;
        font-size: 350%;
        margin-left: 10%;
        margin-bottom: 1em;
        margin-top: 4rem;
    }

    .name {
        width: 50%;
    }

    .nameInput {
        width: 50%;
    }

    .prenom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        margin-right: 0.5rem;
    }

    .nom {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
    }

    .formInput {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
        width: 100%;
    }

    .textInput {
        border: 2px solid #DBDDE4;
        border-radius: 5px;
        height: 2.5rem;
        padding-left: 1rem;
    }

    .submit {
        display: flex;
        justify-content: center;
        width: 140px;
        font-weight: bold;
        color: white;
        border-radius: 30px;
        padding: 15px;
        margin: 1.5rem;
    }

    .submit-button {
        display: flex;
        justify-content: center;
    }
    .bg-primary {
        background-color: #37e6a6;
    }




</style>
