<template>
    <div class="mt-48">
        <div class="mx-48 text-xs">
            <h2 class="text-center font-bold text-2xl mb-16">CONDITIONS GENERALES D’UTILISATION ET DE VENTE UTILISATEURS</h2>

            <h3> 1.	OBJET </h3>

            <p>
                MYMEDIKS est une plateforme de mise en relation par voie électronique, disponible au format web et application mobile, entre les pharmacies d’officine et les Utilisateurs. Elle propose un Service payant de livraison de médicaments et/ou de produits et articles de parapharmacie, assurée par un agent de distribution indépendant.
                Les présentes Conditions Générales d’Utilisation et de Vente (CGUV) sont un accord entre l’Utilisateur et MYMEDIKS, dont les mentions légales sont indiquées ci-dessus.
                En accédant ou en s’inscrivant aux services proposés par MYMEDIKS ou à tout autre service de MYMEDIKS l’Utilisateur accepte d’être lié par les CGUV suivantes. Les services proposés par MYMEDIKS comprennent l’accès à la plateforme MYMEDIKS et la fourniture d’un service de livraison entre l’adresse de la pharmacie d’officine et l’adresse de l’Utilisateur
                Chaque fonctionnalité qui sera ajoutée aux services sera également assujettie aux présentes CGUV. En accédant à ou en utilisant les services, l’Utilisateur accepte d’être lié par les présentes CGUV. L’Utilisateur peut en tout temps consulter la version actuelle des CGUV à l’adresse officines.my-mediks.com/legal. MYMEDIKS se réserve le droit de modifier ces CGVU. Celles-ci seront immédiatement mises à jour à l’adresse officines.my-mediks.com/legal. Il est de la responsabilité de l’Utilisateur de consulter régulièrement ces CGUV pour connaître l’ensemble des mises à jour et des modifications apportées.
            </p>

            <h3> 2.	DEFINITIONS</h3>

            <p> Dans le cadre et pour les besoins des présentes Conditions Générales d’Utilisation et de Vente, les termes et expressions apparaissant en petites majuscules telles que TERMES et EXPRESSIONS listés ci-dessous qu’ils soient mentionnés au singulier ou au pluriel, auront la signification suivante et ce, nonobstant toute autre définition établie entre MYMEDIKS et les Utilisateurs (ci-après « les Parties »). 

            ADRESSE DE LIVRAISON : désigne l'adresse de livraison de la Marchandise renseignée par l'Utilisateur dans l'Application pour une demande de Livraison de Marchandise (pouvant correspondre à l'adresse d'une Consigne).
            ADRESSE ELECTRONIQUE : coordonnées électroniques utilisées par une personne pour émettre ou recevoir des correspondances.
            APPLICATION : désigne l'application informatique consistant en une plateforme de mise en relation par voie électronique (définie par la loi 2016-1088 - 08/08/2016 - article 60) dont la conception, le développement, la maintenance et l'exploitation constituent l'activité et le savoir-faire de la société. Elle est accessible via une application mobile, une application web ou une API (Application Programming Interface - Interface de Programmation Applicative) et permet l'accès aux services, y compris via le site (ou une application) d'une société tierce.
            CGUV : désigne les présentes conditions générales d'utilisation et de vente ainsi que la Politique de confidentialité applicables aux officines.
            COMMANDES : désigne la demande de livraison de marchandises formulée par l’utilisateur. Elle peut concerner la livraison de médicament sur ordonnance et/ou d’articles et produits de parapharmacie. 
            COMPTE : espace privatif de l’officine accessible depuis l'Application avec les Eléments d'Identification et contenant notamment les informations relatives à ces derniers, aux Services fournis par la Société et permettant, le cas échéant, de bénéficier ou effectuer des Livraisons de Marchandise. Il peut être créé ou accessible via l'application ou le site internet d'une société tierce proposant elle-même les Services via sa propre application ou son site intégrant l'Application.
            CONSIGNE : consigne automatique désignée par l'Utilisateur lors de la demande de Livraison afin que l’Agent de Distribution y dépose la Marchandise.
            AGENT DE DISTRIBUTION : personne physique ou morale (selon les cas, désigne le préposé de ladite personne morale) qui propose ses services via l'Application afin d'effectuer des Livraisons pour le compte des Utilisateurs qui en font la demande.
            DESTINATAIRE : désigne la personne physique (y compris le préposé ou représentant d'une personne morale) dont l'identité et les coordonnées sont renseignées sur l'Application par l'Utilisateur comme destinataire de la Marchandise et se trouvant à l'Adresse de livraison ou, le cas échéant, dans un périmètre adjacent proche, sous la réserve de sa désignation, par tout moyen, par l'Utilisateur.
            </p>
            <p>
            DEVIS : désigne le montant calculé par le Pharmacien et dû par l’Utilisateur. Il comprend le Reste à charge, le prix des Produits de santé et de Parapharmacie. 
            DISPENSATION : désigne l’acte par lequel l’Officine délivre les Produits de santé et les Produits de parapharmacie au sein de l’Officine conformément aux conditions légales et règlementaires applicables. A ce titre, l’Officine doit assurer l’intégralité de l'acte de dispensation, associant à la délivrance (i) l'analyse pharmaceutique de l’ordonnance, (ii) la préparation éventuelle des doses à administrer, (iii) la mise à disposition des informations et les conseils nécessaires au bon usage des Produits de santé et des Produits de parapharmacie.
            DOCUMENTS DE SANTE : désignent les documents en lien avec la prise en charge de l’Utilisateur ou le remboursement de ses soins, tels que tout carte vitale, attestation de sécurité sociale, carte mutuelle, CMU.  
            ELEMENTS D'IDENTIFICATION : identifiant de connexion et/ou mot de passe transmis par la Société au Coursier ou à l'Utilisateur leur permettant d'accéder à leur Compte et aux Services.
            EXPEDITEUR : désigne la personne physique (y compris le préposé ou représentant d'une personne morale) dont l'identité et les coordonnées sont renseignées sur l'Application par l'Utilisateur comme expéditeur de la Marchandise et se trouvant à l'Adresse de prise en charge ou, le cas échéant, dans un périmètre adjacent proche, sous la réserve de sa désignation, par tout moyen, par l'Utilisateur.
            INTERFACE OFFICINE : plateforme éditée par la société, dédiée aux officines pour la réception et la gestion des ordonnances ou commandes des patients ou clients.
            INTERFACE UTILISATEUR : plateforme éditée par la société, dédiée aux patients ou clients pour transmettre leurs ordonnances/commandes à l’officine de leur choix aux fins de se faire livrer des médicaments ou articles/produits de parapharmacie.
            INTERMEDIAIRE FINANCIER : partenaire en charge d’opérer les transactions liées aux paiements des médicaments et des produits de parapharmacie par le patient ou client, ainsi que les services fournis par la société. Ils sont de 2 :
            -	Transactions entre patients/clients ET officines/société : Mangopay - Conditions Générales de Vente et d’Utilisation accessibles ici : https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf.
            -	Transactions entre officines ET société : Crédit du Nord - Conditions Générales de Vente et d’Utilisation accessibles ici : https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf.
            LIVRAISON(S): désigne(nt) les prestations de transport et de remise de Marchandise(s) au destinataire désigné par l'Utilisateur qui sont confiées au(x) Coursier Agents de Distribution (s) par l'Utilisateur et réalisées par le(s) Agent(s) de Distribution par le biais de l'Application.
            MARCHANDISE : désigne le(s) Produit(s) de Santé et/ou le(s) Produit(s) de parapharmacie 
            MYMEDIKS : désigne MYMEDIKS, Société par Actions Simplifiées, inscrite au RCS de Paris sous le numéro 822 095 998, dont le siège social est sis 7 boulevard de Rochechouart, 75009 Paris. MYMEDIKS n’est pas un Professionnel de santé. 
            PRODUITS DE PARAPHARMACIE : désignent les produits qui ne sont pas des médicaments, produits et objets mentionnés à l’article L. 4211-1 du code de la santé publique mais qui sont réglementés par le code de la santé publique tels que les cosmétiques ou les produits diététiques non réservés aux nourrissons.
            PRODUITS DE SANTE : désigne le(s) médicaments ou articles et, mentionnés à l’article L. 4211-1 du code de la santé publique, qu’ils soient soumis à prescription médicale ou non à l’exclusion de ceux définis à l’article 4.2, dont l'Utilisateur demande la Livraison, réalisée par l’Agent de Distribution
            OFFICINE : co-contractant exerçant une activité de pharmacien d’officine acceptant l’utilisation des services proposés. Cette condition s’applique tout au long de l’exécution des CGUV entre vous et la société.
            ORDONNANCES : représente l'ensemble des prescriptions établies par un professionnel de santé et qui permet au patient de connaître les détails de son traitement et au pharmacien de délivrer les produits correspondants.
            PARTIE(S) : désigne indifféremment l'une quelconque des parties au contrat de transport, à savoir la société, l’officine, le coursier ou l'utilisateur.
            PLI SCELLE : désigne l’enveloppe préparée et fermée par l’Utilisateur transmise à l’Officine par l’Agent de Distribution. Il contient, le cas échéant, la carte électronique individuelle inter-régimes (carte Vitale), l’ordonnance, la carte d’assurance maladie complémentaire ainsi, que tout autre document demandé par l’Officine. Le Pli scellé est restitué à l’Utilisateur lors de la livraison du Sac scellé à son domicile.
            PRIX DE LIVRAISON : Somme(s) facturée(s) par l’Agent de Distribution à l'Utilisateur au titre d'une Livraison effectuée via l'Application.
            POLITIQUE DE CONFIDENTIALITE : document accessible à l'adresse URL https://my-mediks.com/fr/privacy/ et partie intégrante des présentes CGUV qui a pour objet de définir les conditions dans lesquelles sont traitées vos.
            PRIX : Somme(s) facturée(s) par l’Agent de Distribution à l'Utilisateur au titre d'une Livraison effectuée via l'Application.
            RESEAU : ensemble des moyens matériels et logiciels mis en œuvre pour assurer les communications entre ordinateurs, stations de travail et terminaux informatiques.
            RESTE A CHARGE : part de la dépense de santé qui reste à la charge de l'assuré social après le remboursement de l'Assurance Maladie obligatoire sur présentation d’une ordonnance établie par un professionnel de santé. Cela ne concerne pas les frais de service ou de livraison proposé par la société.
            SERVICES : désignent l'ensemble des services de mise en relation entre les utilisateurs et les Agents de Distribution proposés par le biais de l'Application et des diligences de la Société au titre de cette mise en relation, permettant aux Parties d'obtenir une Livraison de Médicaments ou article/produits de parapharmacie, et pouvant être fournis dans certaines zones du territoire métropolitain français.
            SAC SCELLE : désigne le sac scellé et opaque contenant la Marchandise qui est délivrée par l’Officine dans les conditions légales et règlementaires applicables. A ce titre, l’Officine s’assure que l’emballage comporte le nom et l’adresse d’un seul patient et que sa fermeture est telle que le destinataire puisse s'assurer qu'il n'a pas pu être ouvert par un tiers.
            SOCIETE : désigne mymediks, Société par Actions Simplifiée, telle qu’identifiée dans les mentions légales figurant en en-tête des présentes, société éditrice de la plateforme de mise en relation my-mediks.com, disponible au format web ou application mobile, entre les pharmacies d’officine et les patients ou clients.
            TRANSACTIONS : toute commande effectuée depuis l’application et prépayée en ligne.
            UTILISATEUR : personne physique majeure et capable, ou personne morale représentée par une personne physique dûment habilitée, recherchant un service de Livraison de Marchandise et de mise en relation, par le biais de l'Application, avec un Coursier. Il peut être patient, au sens où il détient une ordonnance médicale établie par un professionnel de santé ou client, au sens où il commande la livraison d’articles ou produits de parapharmacie ou de médicaments en vente libre.
            </p>

            <h3> 3.	CONDITIONS D’ACCÈS AUX SERVICES </h3>
            <p>
            Les présentes CGUV ont pour objet de définir les conditions dans lesquels l’Utilisateur peut bénéficier de(s) Service(s) proposes par MYMEDIKS. L’utilisation du Site Internet, de l’Application, de l’Interface Utilisateur et du Compte MYMEDIKS est soumise à l'acceptation inconditionnelle et sans réserve par l’Utilisateur des CGUV qui lui sont présentées pour acceptation lors de l’activation de son Compte mymediks. En acceptant les CGUV lors de l’activation de son Compte, l’Utilisateur reconnaît donc avoir lu et compris l’intégralité des présentes CGUV, et les accepter sans restriction ni réserve. Si l’Utilisateur n’accepte pas ces CGUV, son Compte ne pourra être activé et il ne pourra pas bénéficier des Services proposés par MYMEDIKS. 
            3.1 Champ d’application 
            Les présentes CGUV sont en vigueur à compter du 29/04/2021. Elles sont applicables, à compter de leur acceptation par l’Utilisateur, à toute utilisation qu’il fera des Services. 
            3.2 Durée 
            Les CGUV sont applicables, à compter de leur acceptation par l’Utilisateur pendant une durée indéterminée et jusqu’à la résiliation de son Compte MYMEDIKS ou de la cessation de leur utilisation des Services, du Site Internet, de l’Application et de la Plateforme. 
            3.3 Modification des CGUV 
            MYMEDIKS est susceptible de modifier les CGUV à tout moment, notamment pour prendre en compte les évolutions légales et réglementaires. La version qui prévaut est celle qui est accessible en ligne. Tout Utilisateur est donc tenu de se référer à leur version accessible en ligne à la date de son accès et de son utilisation des Services. L’Utilisateur est expressément informé que l’unique version des CGUV des Services qui fait foi est celle qui se trouve en ligne sur le Site, ce qu’il reconnaît et accepte sans restriction. L’Utilisateur dispose, en se connectant à son Compte, d’un accès à la version des CGUV qui s’applique. 
            4.	CRÉATION D’UN COMPTE UTILISATEUR 
            4. 1 Conditions d’accès au Service 
            Le Service est réservé aux Utilisateurs majeurs et capables. 
            Sous réserve de la prise de connaissance et de l’acceptation de l’intégralité des présentes CGUV sans restriction ni réserve, le Service est également disponible pour les Utilisateurs mineurs âgés d’au moins 15 ans. Lorsque le mineur est âgé de moins de 15 ans, l’utilisation du Service ne sera possible que dans la mesure où le consentement est donné ou autorisé par le titulaire de l’autorité parentale à l’égard du mineur. Cette autorisation sera matérialisée par la mention de la présence et de l’acceptation conjointe, lors de la création du compte, du titulaire de l’autorité parentale du mineur ou, dans les conditions permises par les dispositions applicables, du mineur accompagné par la personne majeure de son choix. 
            Pour pouvoir accéder à l’ensemble des Service et afin d’en assurer son bon fonctionnement, l’Utilisateur devra se connecter sur la Plateforme en renseignant ses Identifiants de Connexion. 
            Le Patient Utilisateur s’engage à accéder au Service en utilisant sa véritable identité et s’engage à renseigner des informations authentiques lors de la création et de la validation de son compte. 
            </p>
            <h3> 5.	PRESENTATION GENERALE DU SERVICE</h3>

            <p>
            Le site,  la Plateforme et l’Application proposent principalement des services de mise en relation entre les Officines et les Utilisateurs puis d’assurer un  Service de livraison à domicile de Produits de santé et de Produits de Parapharmacie, Les services proposés par MYMEDIKS sont accessibles sur le Site et l’Application mobile.
            Le Service comprend :
            -	La création d’un Compte Utilisateur ;
            -	Un outil de géolocalisation des Officines ;
            -	L’accès à un espace de stockage sécurisé ;
            -	L’envoi de son ordonnance et ses documents de santé par voie électronique
            -	Le choix de produits de Santé et de Paraphamacie
            -	Une messagerie sécurisée et instantanée permettant à l’Utilisateur d’échanger directement avec l’Officine;
            -	La collecte du Pli scellé au domicile de l’Utilisateur et sa remise à l’Officine du Pharmacien ;
            -	La collecte du Sac scellé à l’Officine et sa livraison au domicile de l’Utilisateur ;
            -	La création d’un Portefeuille Électronique.
            La Dispensation et la vente de Produits de santé et de Produits de Parapharmacie n’est pas comprise dans le Service rendu par MYMEDIKS, et est réalisée sous la responsabilité exclusive du Pharmacien, au sein de l’Officine, dans les conditions légales et règlementaires applicables.
            Pour toute question, relative à la souscription ou au Service, l’Utilisateur peut contacter s’il le souhaite le service client mymediks :
            -	Par courrier à l’adresse suivante : « MYMEDIKS », 7 boulevard de Rochechouart 75009 Paris ;
            -	Par le formulaire de contact en ligne disponible sur le Site [ici].
            5.1  Limites
            Aux fins de préserver la sécurité des Agents et de respecter les conditions de conservation des produits transportés, MYMEDIKS exclut expressément du Service de livraison, les produits suivants :
            -	Les médicaments stupéfiants ou psychotropes ;
            -	Les médicaments hautement actif et radioactifs ;
            -	Les produits thermosensibles.
            L’Utilisateur reconnaît que le Service de livraison fournit par MYMEDIKS n’inclut pas la vente de Produits de santé et de Produits de parapharmacie et, ne remplace pas l’acte de dispensation pharmaceutique dont le Pharmacien reste personnellement responsable. En effet, le Service fourni par MYMEDIKS n’inclut d’aucune manière (i) l'analyse pharmaceutique de l'ordonnance, (ii) la préparation des doses à administrer et (iii) la mise à disposition d’informations et conseils nécessaires au bon usage des Produits de santé, effectué par le Pharmacien, au bénéfice de ses patients.

            L’Utilisateur reconnait que le Site ne constitue pas un site de commerce électronique de Produits de santé ou de Produits de parapharmacie, aucun catalogue n’est proposé aux Utilisateurs mymediks.
            </p>

            <h3> 6.	CREATION ET GESTION D’UN COMPTE UTILISATEUR</h3>
            <p>
            Pour accéder à certains Services proposés par Mymediks, l’Utilisateur devra disposer d’un Compte pour y accéder. Tous les Services nécessitent obligatoirement la création d’un Compte Mymediks.
            6.1 Création et activation du Compte Utilisateur

            Toute personne qui souhaite créer un Compte Utilisateur reconnait disposer de la capacité juridique lui permettant de s’engager au titre des CGUV. Elle s’engage à fournir des informations complètes, exactes et actualisées, et à ne pas usurper l’identité d’un tiers.
            La création du Compte Utilisateur est gratuite et s’effectue par en renseignant le formulaire directement sur le Site.
            L’Utilisateur recevra confirmation de son inscription par courrier électronique et lui indiquant la démarche à suivre afin de procéder à la première connexion à son Compte Utilisateur.
            Lors de la création du Compte Mymediks, l’Utilisateur devra renseigner : 
            -	Les données qui permettent son identification sous son entière responsabilité, contrôle et direction ;
            -	Ses Identifiants (adresse email et son mot de passe).
            Un code d'activation lui sera alors adressé par email ou par SMS. Le Patient devra alors activer son Compte en renseignant son code d’activation, et cocher deux cases lui permettant de lire et d’accepter les CGUV et la politique de confidentialité́. 
            Un email automatique lui sera alors adressé lui indiquant que son compte a bien été activé. 
            6.2 Accès au Compte MYMEDIKS
            Les Utilisateurs peuvent directement accéder à leur propre Compte MYMEDIKS à partir de la Plateforme MYMEDIKS disponible via l’URL suivante : https://www.my-mediks.com  ou à partir du Site Internet en cliquant sur l’onglet « Se Connecter » qui renverra vers la Plateforme. La consultation directe du Compte MYMEDIKS par l’Utilisateur nécessite à chaque connexion la communication de ses Identifiants de Connexion. 
            Le Compte du Patient se déconnectera automatiquement au bout d’un certain laps de temps d’inactivité. Pour se reconnecter, l’Utilisateur devra communiquer une nouvelle fois ses Identifiants de Connexion. 
            6.3 Authentification de l’utilisateur

            Tout accès au Compte Utilisateur repose sur un système d’authentification de l’Utilisateur qui doit :
            -	Saisir son adresse email ;
            -	Saisir le mot de passe choisi au moment de la création de son Compte.
            Les Identifiants de l’Utilisateur sont strictement personnels. Ils ne doivent sous aucun prétexte, être communiquer à des tiers.
            L’Utilisateur est entièrement responsable de la conservation et de l’utilisation de ses Identifiants et de toutes activités exercées sur son Compte Utilisateur. Il doit prendre toutes les mesures nécessaires pour empêcher une utilisation non autorisée ou frauduleuse de son Compte Utilisateur.
            Tout accès au Compte de l’Utilisateur à l’aide de ses Identifiants est réputé fait par l’Utilisateur.
            Si l’Utilisateur constate ou suspecte une utilisation non autorisée ou frauduleuse de son Identifiant, il doit alerter immédiatement MYMEDIKS à l’adresse suivante : contact@my-mediks.com
            À compter de cette notification, si l’Utilisateur le demande, MYMEDIKS procédera au blocage du Compte Utilisateur. Pour accéder au Service, l’Utilisateur devra se réinscrire avec de nouveaux Identifiants.
            6.4 Gestion des Identifiants de Connexion 
            Les Identifiants de Connexion sont strictement personnels et confidentiels. Dans l’hypothèse où l’Utilisateur aurait égaré ses Identifiants de Connexion, il devra suivre la procédure prévue à cet effet. 
            Chaque Utilisateur est entièrement responsable de l'usage qu’il fait de ses Identifiants de Connexion. 
            Il s'engage à conserver secrets et à ne pas divulguer sous aucune forme et d’aucune manière à des tiers. En cas de perte, de vol ou de toute utilisation frauduleuse de ses Identifiants de Connexion, l’Utilisateur devra immédiatement en informer Mymediks. 
            Son Compte et les Services associés pourront temporairement être suspendu dans l’hypothèse où l’Utilisateur et MYMEDIKS suspectent une fraude. 
            L’Utilisateur pourra alors solliciter le remplacement de ses Identifiants de Connexion lorsqu'il existe un risque que ceux-ci soient utilisés par un tiers. Le Patient Utilisateur devra être en mesure de prouver son identité ou d’apporter des éléments prouvant que la demande émane de lui lorsqu’il effectuera une demande visant à modifier ses Identifiants de Connexion. 
            Chaque Utilisateur est en conséquence seul responsable de toute utilisation de la Plateforme et des Services faite via son Compte. Toute connexion ou transmission de données effectuée en utilisant les Identifiants sera réputée avoir été effectuée par l’Utilisateur et sous sa responsabilité exclusive. 
            MYMEDIKS ne pourra en aucun cas être tenu pour responsable de l’utilisation frauduleuse du Compte d’un Utilisateur si ce dernier a informé directement ou indirectement, volontairement ou involontairement un tiers de ses Identifiants. 

            6.5 Résiliation
            6.5.1 Résiliation par l’Utilisateur
            L’Utilisateur peut à tout moment demander la suppression de son Compte MYMEDIKS, en se rendant dans la Rubrique « Mon compte » / « Paramètres » accessible depuis son Compte Patient ou à l'adresse de contact MYMEDIKS, à tout moment, sans avoir à justifier de motifs, sous réserve du respect de ses obligations au titre des présentes CGUV. L’Utilisateur sera informé par courrier électronique de la confirmation de la résiliation de son Compte Utilisateur.
            6.5.2 Résiliation par MYMEDIKS
            En cas de manquement grave du Patient aux dispositions des présentes CGUV, MYMEDIKS se réserve le droit de résilier son Compte Utilisateur, sans préavis ni mise en demeure, et ce, sans préjudice des autres dispositions des présentes CGUV applicables.

            En cas de manquement de l’Utilisateur aux présentes CGUV, MYMEDIKS lui envoie un courrier électronique le mettant en demeure de se conformer aux présentes CGUV. MYMEDIKS se réserve le droit de résilier le Compte Utilisateur dans un délai de sept (7) jours en cas de mise en demeure restée infructueuse. La résiliation intervient sans préjudice de tous dommages et intérêts qui pourraient être réclamés par MYMEDIKS en réparation des préjudices subis par elle du fait de tels manquements. L’Utlisateur est informé par courrier électronique de la confirmation de la résiliation de son Compte Utilisateur.
            6.5.3 Sort des données de l'Utilisateurs après résiliation
            La suppression du Compte MYMEDIKS entraîne la suppression du dispositif MYMEDIKS de tout contenu, données et informations contenues ou créées à l’aide du Compte MYMEDIKS, sans préjudice des fonctionnalités de la Plateforme relatives à la portabilité des Données à caractère personnel. 

            6.6 Suspension de l’accès au Service 
            MYMEDIKS se réserve le droit de suspendre/ limiter et/ou refuser l'accès aux Services et au Compte MYMEDIKS sans indemnité dans les cas suivants : 
            -	Immédiatement en cas de perte, vol et/ou utilisation frauduleuse des Identifiants de Connexion de l’Utilisateur dont ce dernier l'a informé dans l’optique première de protéger le contenu de son Compte et de ses informations; 
            -	Dans un délai de vingt-quatre heures (24 h) après avoir informé l’Utilisateur en cas d'opérations de maintenance importante ; 
            -	Immédiatement dans l’hypothèse où le comportement d’un Utilisateur serait susceptible d’impliquer un danger grave et imminent pour la sécurité des données, de porter gravement atteinte aux règles déontologiques applicables ou à la réputation de MYMEDIKS ou d’une OFficine, ou s’il détournerait de manière grave l’usage normal de la Plateforme MYMEDIKS ; 
            -	Immédiatement, dans l’hypothèse où l’Utilisateur fournirait des informations fausses, inexactes, obsolètes, incomplètes, trompeuses ou mensongères ou de nature à induire en erreur. 
            La responsabilité de MYMEDIKS ne pourra en aucun cas être engagée en raison de ces interruptions et des conséquences qui peuvent en découler pour l’Utilisateur étant entendu que ce dernier utilise les Services à ses risques et périls. 
            </p>
            <h3> 7.  PASSER UNE COMMANDE</h3>
            <p>
            MYMEDIKS est un intermédiaire en livraison de Produits de Santé et de Produits de Parapharmacie dont le rôle est de mettre en relation au moyen du Site Internet, de l’Application, de l’Interface des Utilisateurs souhaitant se faire livrer des médicaments et /ou des produits de parapharmacie, des Officines en charge du traitement de la Commande et de sa Dispensation, et des Agents indépendants mandatés pour effectuer la livraison (collecte et dépose) des produits commandés en Officine.
            </p>
            <p>
            MYMEDIKS propose à l’Utilisateur la possibilité après création d’un Compte sur la plateforme :
            -	de se géolocaliser en indiquant son adresse de livraison directement sur son Compte. 
            -	 de désigner l’Officine de son choix dans un rayon de 15 kilomètres autour de l’adresse indiquée
            -	de scanner et stocker ses documents de santé suivants :
            o	 son ordonnance ;
            o	 son attestation de sécurité sociale ; 
            o	sa carte d’assurance maladie complémentaire (carte mutuelle).
            -	de sélectionner dans la liste les Produits de Santé sans ordonnance (hormis les médicaments de classe stupéfiants) et les Produits de Parapharmacie
            -	de remplir un Questionnaire de Santé à destination de l’Officine
            -	d’être mis en relation avec l’Officine sélectionnée via messagerie instantée pendant le traitement de la commande ;
            -	d’obtenir un Devis de la part de l’Officine correspondant au Reste à Charge de l’ordonnane et du prix des Produis de Santé et de Parapharmacie ;
            -	d’effectuer le paiement de sa commande en ligne via la plateforme d’intermédiation de paiement en ligne Mangopay ;
            -	d’être effectivement livré à l’adresse qu’il aura indiqué dans les meilleurs délais à compter de la confirmation de la livraison.
            MYMEDIKS sélectionne et géolocalise l’Agent le plus proche du lieu de livraison (pour les commandes nécessitant une ordonnance) ou de l’Officine sélectionnée (pour les commandes de Produits de Santé sans ordonnance), lequel récupérera chez l’Utilisateur dans un pli sous scellé la carte vitale, la carte mutuelle et l'ordonnance avant d’effectuer sa course puis de revenir livrer en main propre les Produits et/ou médicaments sous scellés et sac isotherme (le cas échéant).
            </p>

            <h3> 8.	TARIFICATION</h3>
            <p>
            8.1 Généralités
            L'accès et l'utilisation du Site, de la Plateforme et de l'Application stricto sensu sont gratuits pour l'Utilisateur, MYMEDIKS ne lui demandant aucune redevance à ce titre.

            Toutefois, l'Utilisateur reconnaît et accepte que restent à sa charge exclusive :
            •	tous les frais, coûts, dépenses afférents à l'accès, au téléchargement et à l'utilisation du Site, de la Plateforme et de l'Application que ce soit les frais matériels, logiciels, liés à internet dont ceux facturés par l'opérateur de télécommunications pour l'utilisation du réseau et le partage des données ;
            •	le paiement des Frais de Livraison auprès de MYMEDIKS
            •	le paiement du Devis auprès de l’Officine concernée selon les conditions de paiement propres à ce dernier, étant précisé que le paiement des Devis s'effectue dans les conditions définies à l'article 12.2 « Paiement des Commandes ».
            Pour toute information concernant la prise en charge du ticket modérateur et de la dispense d'avance de frais, nous vous invitons à consulter notamment le site www.ameli.fr ainsi que le site www.complementaire-sante-solidaire.gouv.fr (Fond CMU-C).

            8.2. Paiement des commandes
            8.2.1 Moyens de paiement 
            Pour des raisons de sécurité et en vue d'assurer un règlement effectif des Commandes par l’Utilisateur à l’Officine, le paiement d'une Commande est effectué, par carte bancaire par l'intermédiaire du prestataire de services de paiement Mangopay, SA, 59 Boulevard Royal, L-2449 Luxembourg (ci-après « MANGOPAY »). L’Utilisateur doit préalablement lire, comprendre et accepter les conditions générales d'utilisation de Mangopay (https://www.mangopay.com/wp-content/uploads/CGU-EP-FR.pdf).
            Le paiement par l’intermédiaire du prestateire de service de paiement permet deux opérations distinctes :
            - le paiement en ligne du Service de livraison directement auprès de MYMEDIKS ;
            - la validation de la pré-autorisation et l’activation du paiement de la Commande des produits de santé et de parapharmacie auprès de l’Officine à l’issue de leur livraison par l’Agent de Distribution
            8.2.2 Paiement des Produits de santé et des Produits de parapharmacie 
            A la saisie des informations relatives à sa carte bancaire, l’Utilisateur accepte la mise en place d’une pré-autorisation de paiement correspondant au montant du Service et des Produits de santé et des Produits de parapharmacie.
            Aucun paiement des Produits de santé et des Produits de parapharmacie n’intervient auprès ou par l’intermédiaire de MYMEDIKS.
            La pré-autorisation de paiement permettra une fois que la livraison du Sac scellé est terminée de déclencher le paiement du Service vers MYMEDIKS et de la Commande vers l’Officine par l’activation contrôlée et sécurisée du Portefeuille Electronique de l’Utilisateur.
            Ainsi, le paiement des Produits de santé et des Produits de parapharmacie ne s’effectue qu’au moment de leur Dispensation par le Pharmacien, au sein de l’Officine, directement sur son compte, sans transit sur le compte de MYMEDIKS.
            Après validation par le Pharmacien du paiement des Produits de santé et des Produits de parapharmacie par l’Utilisateur, la facture sera placée dans le Colis scellé par le Pharmacien.
            Le prix de la Commande sont indiqués en euros € et renseigné par l’Officine lors de l’établissement du Devis.
            Toutefois, l’Utilisateur est averti et accepte que MYMEDIKS n'a pas de contrôle sur le montant de la Commande qui sera effectivement saisi par l’Officine à l'issue du traitement de la Commande et ne saurait en aucun cas être responsable si le tarif facturé par l’Officine est erroné.
            8.2.3 Paiement du Service de livraison
            Le recours au Service de livraison via le Site oblige l’Utilisateur au paiement de ce Service.
            Le prix du Service comprend les déplacements et livraisons réalisées par l’Agent de Distribution. Il ne comprend pas le prix des Produits de santé et des Produits de parapharmacie réglé directement par l’Utilisateur à l’Officine à partir de l’activation du Portefeuille électronique par ce dernier.
            En cas de violation des CGUV notamment, en cas de demande de livraison pour les produits exclus du Service, MYMEDIKS se réserve le droit de facturer l’Utilisateur.
            Le prix de la Livraison est indiqué sur Compte Utilisaateur au moment du choix de l’Offcine et varie selon la distance de livraison.
            Les tarifs sont indiqués toutes taxes comprises (TTC).
            L’Utilisateur est informé que le compte bancaire du titulaire de la carte de paiement qu’il aura utilisée pour acquitter le montant du Service ne sera débité qu’après la livraison effective.
            Pour tout paiement par carte bancaire, l’Utilisateur garantit être titulaire de la carte bancaire ou avoir l’autorisation expresse du titulaire pour régler l’achat effectué. La liste des cartes bancaires admises est renseignée sur le Site.
            Toute utilisation frauduleuse d’une carte bancaire donnerait lieu à la suspension du Compte Utilisateur.
            Les données bancaires sont conservées conformément aux dispositions légales en vigueur. La confidentialité des données bancaires transmises par l’Utilisateur à un établissement bancaire agréé est assurée dans un environnement sécurisé.
            Après l’autorisation du paiement par l’Utilisateur, une facture sera adressée à l’Utilisateur par courrier électronique et sera disponible dans son Compte Utilisateur, dans la rubrique « Mes commandes ».
            8.2.4 Paiement par carte bancaire via la plateforme Mangopay

            L’Utilisateur doit être titulaire de la carte bancaire utilisée pour le paiement ou à défaut être dûment autorisé à l'utiliser pour procéder au règlement. Aussi, l’Utilisateur s'engage à ce que le compte bancaire associé à la carte de paiement utilisé pour régler la Commande soit suffisamment approvisionné pour satisfaire à son obligation de paiement. Une pré-autorisation bancaire sera réalisée et mise en œuvre à la confirmation du Devis. L’Utilisateur reconnaît expressément que la communication de ses données de carte bancaire au moment du paiement du Devis vaut pré-autorisation et autorisation de débit de son compte à concurrence du montant de la Commande saisie par l’Officine au moment du traitement de la Commande et des frais de Livraison. Le Patient est informé qu'en cas d'erreur liée aux informations bancaires fournies ou échec de la pré-autorisation ou de l'autorisation de débit, l'accès et le bénéfice du Service de Livraison sera empêché.

            Il est précisé que MYMEDIKS n'a jamais accès aux informations bancaires de l’Utilisateur, les paiements en ligne et les traitements bancaires afférents étant intégralement gérés par Mangopay. Le paiement du Service de Livraison auprès de MYMEDIKS et de la Commande des produits de santé et de parapharmacie auprès de l’Officine sera effectif à l’issue de leur livraison par l’Agent de Distribution
            MYMEDIKS ne saurait être responsable d'une erreur sur le tarif appliqué et/ou le montant à prélever saisi par l’Officine ou sur le montant effectivement débité par la plateforme Mangopay. En cas d'échec du paiement réalisé sur la plateforme Mangopay, l’Utilisateur se mettra en relation avec l’Officine afin de s'accorder pour régler la somme due (par exemple lors de la prochaine Commande ou par voie postale) ce, sous leur entière responsabilité. L’Utilisateur  s'engage à respecter les règles et éventuels délais de paiement complémentaires qui seront convenus avec l’Officine.
            8.2.5 Sécurité sociale et assurances complémentaires santé
            MYMEDIKS n'intervient pas dans les formalités de prise en charge des Produits de Santé et des Produits de Parapharmacie. L’Officine et l’Utilisateur restent responsables des formalités de prise en charge par la sécurité sociale et les tiers payants/assurances complémentaires de santé. MYMEDIKS ne saurait être responsable d'une éventuelle erreur dans la prise en charge de la Commande.
            </p>

            <h3> 9.	OBLIGATIONS GENERALES DE L’UTILISATEUR </h3>

            <p>
            9.1 Règles de bonne conduite sur le Site et sur la Plateforme 
            L’Utilisateur est informé que l'ensemble des lois et règlements en vigueur sont applicables sur Internet. 
            L’Utilisateur s’engage à :
            -	veiller à conserver ses Identifiants de Connexion confidentiels afin de protéger les informations présentes sur la Plateforme ; 
            -	n’utiliser le Service qu’à des fins personnelles et privées : le Service ne peut en aucune façon être utilisé à des fins commerciales ou lucratives, et en particulier ne peut en aucun cas être utilisé pour recueillir des informations commerciales ; 
            -	ne pas accéder et utiliser le Site Internet, l’Application, l’Interface Utilisateur, le Compte et Services fournis par MYMEDIKS à des fins illicites ou dans le but de porter atteinte à la réputation et/ou à l'image de MYMEDIKS, des Officines, des Agents de Distribution, plus généralement à porter atteinte aux droits, notamment de propriété intellectuelle de MYMEDIKS et/ou des Officines ; des Agents de Distribution et/ou de tiers ; 
            -	ne pas utiliser des dispositifs ou des logiciels autres que ceux fournis par MYMEDIKS dans le but d’affecter ou de tenter d’affecter le bon fonctionnement du le Site Internet, l’Application, l’Interface Utilisateur, le Compte et Services ou encore d’extraire ou de modifier tout ou partie du Site Internet, de l’Application, de l’Interface, du Compte et/ou Services ; 
            -	ne pas copier tout ou partie du contenu présent sur le Site Internet, l’Application, l’Interface, le Compte, les Services ou sur quelque support que ce soit, sans autorisation écrite préalable de la part de MYMEDIKS ; 
            -	De façon générale, accéder et utiliser le Site Internet, l’Application, l’Interface, le Compte et tous les Services en toute bonne foi, de manière raisonnable, non contraire aux termes des présentes et pour une utilisation strictement personnelle et à des fins non lucratives. 
            Sans préjudice de stipulations de l’article 4.2, en cas de manquement à l’un ou l’autre de ces engagements, l’Utilisateur reconnaît et accepte qu’MYMEDIKS aura la faculté, de lui refuser, l'accès à tout ou partie du Site Internet, de l’Application, de l’Interface, du Compte des Services. 
            9. 2 Responsabilité des Utilisateurs 
            L’Utilisateur est seul responsable de l'utilisation qu'il fait du Site, de l’Application, de l’Interface et des Services auxquels il accède depuis le Site, l’Application et l’Interface. 
            L’Utilisateur reconnaît et accepte à cet égard qu'il sera responsable de toute réclamation ou procédure formée contre MYMEDIKS, du fait de l'utilisation non-conforme par lui des Services et/ou du Site, de l’Application, de l’Interface. 
            Le Compte MYMEDIKS d’un Utilisateur est non cessible. 
            L’Utilisateur est responsable de tous les dommages qu'il pourra causer à MYMEDIKS du fait du non-respect des CGUV et de la règlementation en vigueur.
            L’Utilisateur est seul responsable des Données qu’il héberge, stocke ou communique à MYMEDIKS, aux Officines ou aux tiers que ce soit via le Site ou les Plis scellés, quelle que soit la nature des Données. MYMEDIKS ne saurait être tenue pour responsable desdites Données hébergées, stockées ou communiquées par l’Utilisateur.
            L’Utilisateur est responsable de la sauvegarde et de la conservation de ses Données sur son terminal. Il reconnait avoir pris connaissance de la durée de conservation de ses Données sur le Site. MYMEDIKS ne pourra être tenue responsable de la perte des Données de l’Utilisateur imputable à ce dernier notamment, de son omission à sauvegarder ses Données.
            Tous matériels et logiciels nécessaires à l’accès et à l’utilisation du Site ou des Services restent exclusivement à la charge de l’Utilisateur qui est donc seul responsable du bon fonctionnement de son équipement ainsi que de son accès à internet.
            L’Utilisateur accepte de ne pas utiliser le Site notamment, les Données hébergés, stockés, transmis ou reçus à des fins impropres, illicites, contraires à l’ordre public, interdites par les lois ou règlements applicables, ni porter atteinte aux droits de MYMEDIKS, des Pharmaciens ou des tiers. MYMEDIKS n’est en aucun cas responsable de tels agissements de la part des Utilisateurs, ni de toute transmission ou message de harcèlement, de menace, d’action diffamatoire, offensant, non-respectueux ou illégal suite à l’usage du Site.
            Les opinions exprimées par l’intermédiaire du Site par d’autres Officines, Utilisateurs ou des tiers sont les leurs, et ne reflètent pas nécessairement celles de MYMEDIKS.
            Dans le cas où la responsabilité de MYMEDIKS serait recherchée du fait de l’un des cas prévus au présent article, l’Utilisateur indemnisera MYMEDIKS de l’ensemble des conséquences de toute réclamation, action et/ou procédure intentée de ce fait contre MYMEDIKS, quelle qu’en soit la nature.
            L’Utilisateur indemnise MYMEDIKS, ses représentants de tous les frais (y compris les honoraires, frais et dépens de justice) et dommages et intérêts liés aux réclamations et actions en justice se rapportant (i) à l’utilisation des Services par l’Utilisateur et/ou ses Proches de manière illégale, immorale, frauduleuse, ou hors Contrat; (ii) aux Données Utilisateur qu’il renseigne et leur utilisation par MYMEDIKS et/ou les Acteurs de santé et leurs Assistants; (iii) à la violation de droits de Propriété Intellectuelle de MYMEDIKS ainsi que de tout tiers, du fait de l’utilisation des Services par l’Utilisateur. 
            L’Utilisateur peut à tout moment renoncer à utiliser le Service et demander la suppression de son Compte Utilisateur mais reste responsable de toute utilisation antérieure.
            L'accès et l’utilisation du Site Internet, de l’Application, de l’Interface Utilisateur, du Compte et des Services impliquent impérativement de la part de l’Utilisateur de disposer de certains équipements informatiques (à savoir notamment soit un ordinateur, soit une tablette, soit un téléphone de type smartphone, moyens de communication électronique, accès internet) permettant cet accès et cette utilisation, étant entendu que le Patient supporte seul les frais y afférents. 
            L’Utilisateur est informé que pour bénéficier de certains Services, il devra impérativement bénéficier d’un téléphone type smartphone. 
            Les temps de réponse et le taux de transfert des informations circulant à partir du Site Internet, de l’Application, de l’Interface Utilisateur, du Compte vers le réseau Internet ne sont pas garantis par mymediks. L’Utilisateur reconnait que la disponibilité de ces derniers est liée à des caractéristiques inhérentes aux réseaux de communications électronique et des caractéristiques techniques de son mode de connexion (câble, ADSL, 3G, 4G etc.) et de son accès Internet. 
            Il appartient à chaque Utilisateur de prendre toutes les mesures appropriées de façon à protéger ses propres données, logiciels et matériels, de la contamination par des virus ou autres formes d’attaques circulant éventuellement à travers le Site Internet, du Compte mymediks, la Plateforme. 
            L’Utilisateur est informé des risques inhérents à l’utilisation d’Internet, et tout particulièrement en termes de défaut de sécurité dans la transmission des données et de continuité non garantie dans l’accès au Site Internet, à l’Application, à l’Interface Utilisateur et au Compte. 
            En aucun cas MYMEDIKS ne saurait être tenu responsable de ces risques et de leurs conséquences, préjudiciables tant pour le simple visiteur du Site Internet que pour l’Utilisateur. 
            </p>
            <h3> 10.	OBLIGATIONS, RESPONSABILITES ET GARANTIES DE MYMEDIKS </h3>

            <p>
            10.1 Obligations de MYMEDIKS 
            MYMEDIKS fournit ses Services sans aucune garantie de disponibilité permanente ou d’accès continu. MYMEDIKS s'engage néanmoins à fournir ses meilleurs efforts pour assurer que l'accès et le fonctionnement du Site et de la Plateforme soient assurés 24 heures sur 24 et 7 jours sur 7 sans interruption. 
            Les Services de mise en relation et de livraison proposés par MYMEDIKS sont disponibles durant les créneaux horaires d’ouverture des Officines, de 9h à 19h du lundi au dimanche.
            Cependant, compte tenu des spécificités du réseau Internet, MYMEDIKS n’offre aucune garantie de continuité du Service, n’étant tenue à cet égard qu’à une obligation de moyens. 
            Ainsi, MYMEDIKS ne peut voir sa responsabilité engagée en cas d'indisponibilité temporaire du Site, de la Plateforme et/ou du Service ou en cas de bug et notamment : 
            -	En cas de maintenance du Site, de la Plateforme ou des serveurs sur lequel il est hébergé ou en cas de panne ; 
            -	En cas de disfonctionnement ou défaillance des réseaux Internet et/ou mobiles ; 
            -	En raison de cas de Force Majeure ou d’actes de malveillance. 
            MYMEDIKS s’engage à ne réclamer aucun pourboire et à se limiter strictement à l’exécution des prestations décrites aux présentes, permettant la livraison à domicile de produits de santé par un Agent de Distribution, prévue aux articles R. 5125-47 à R. 5125-49 du code de la santé publique.
            10. 2 Responsabilité de MYMEDIKS
            L’Utilisateur reconnaît la validité des limites de responsabilité prévues au présent article et s’interdit de les remettre en cause. Cet engagement est une condition essentielle et déterminante du consentement de l’Utilisateur aux présentes CGUV. 
            MYMEDIKS ne pourra être tenu responsable de la non-exécution ou de la mauvaise exécution de la Commande proposée par l’Officine et ne pourra être redevable des frais éventuels engagés par l’Utilisateur ou par l’Officine dans ce cadre. 
            MYMEDIKS ne pourra être tenu responsable dans l’hypothèse où l’Officine n’honorerait pas la prise en charge de la Commande de l’Utilisateur, ni en cas d’indisponibilité ou de retard dans la prise en charge. 
            L’Officine est exclusivement responsable en cas de manquement à ses obligations notamment, liés au colisage, à l’étiquetage des Sacs scellés, MYMEDIKS ne saurait en être responsable auprès de l’Utilisateur ou de tout tiers et ne traite pas leurs réclamations. En aucun cas mymediks n’est responsable en cas d’erreur sur les Produits de santé et les Produits de parapharmacie délivrés par l’Officine ou sur leur facturation, MYMEDIKS ayant la seule responsabilité de la livraison du Pli scellé et du Sac scellé.

            MYMEDIKS ne sera tenue à aucun remboursement ou indemnité en cas de force majeure, d’absence ou refus du destinataire, d’adresse inaccessible ou erronée, ou pour tout autre raison qui ne serait pas imputable à MYMEDIKS.

            10.3 Réclamation
            Pour toute information relative au fonctionnement du Service accessible via l’Interface Mymediks, l’Utilisateur est invité à se reporter à la rubrique Contactez-nous accessible sur le Site ainsi que sur l’Interface ou à adresser sa réclamation à notre service client déployé à cet effet aux coordonnées mentionnées à l’Article 14. 
            </p>

            <h3> 11.	PROPRIÉTÉ INTELLECTUELLE </h3>

            <p>
            MYMEDIKS est titulaire des droits de propriété intellectuelle exclusifs tant de la structure générale du Site Internet, de l’Application, de l’Interface, que de son contenu (textes, programmes, charte graphiques, slogans, graphiques, images, photos, témoignages, et autres contenus et composantes) et de ses signes distinctifs (marques, logos, dénominations sociales, vidéos, voix, sons, sigles, noms commerciaux, documentation commerciale et publicitaire, enseignes, nom de domaine). 
            Dès lors, toute représentation, reproduction, modification, création d’œuvres dérivées, dénaturation et/ou exploitation totale ou partielle du Site, de son contenu, de ses Services, et de ses signes distinctifs par quelque procédé que ce soit et sur quelque support que ce soit, sans l'autorisation expresse et préalable de MYMEDIKS, est prohibée et constitue des actes de contrefaçon au sens des articles L.335-2 et L.716-1 du Code de la propriété intellectuelle. 
            Aucune stipulation des CGUV ne peut être interprétée comme une cession de droits de propriété intellectuelle que ce soit tacitement ou d’une autre manière. 
            11.1 Eléments des tiers
            Les éléments appartenant à des tiers, telle que marques, dessins, modèles, images, textes, photos, logos, bases de données sans que cette liste ne soit exhaustive, sont la propriété exclusive de leur auteur et sont protégés à ce titre par les droits de propriété intellectuelle qui leur sont reconnus par la législation en vigueur.

            L'Utilisateur s'interdit de porter atteinte, directement ou indirectement, au droit de propriété de tiers, dont les éléments sont présents sur le Site, la Plateforme, l'Application et s'interdit d'exploiter, de quelque manière que ce soit, les noms, les marques, les logos, les informations et tous les documents qui lui sont communiqués, d'une manière générale, dans le cadre de l'exécution des présentes. L'Utilisateur s'engage à respecter l'intégralité des droits de tiers, dont des éléments sont présents sur le Site, la Plateforme, l'Application et s'interdit de susciter toute analogie dans l'esprit du public à quelque fin que ce soit. A cet effet, l'Utilisateur s'engage à prendre toutes les mesures nécessaires à la protection desdits droits à l'égard de tout tiers et, notamment, maintiendra en état toutes les mentions de propriété qui seront portées sur l'ensemble des données, informations et plus généralement sur les éléments consultables sur le Site, la Plateforme, l'Application ou rendus accessibles par un tiers.

            11.2 Liens hypertextes/sources externes
            Le Site, la Plateforme, l'Application peut inclure des liens vers des sites Internet ou d'autres sources Internet. Dans la mesure où MYMEDIKS ne peut contrôler ces sites et ces sources externes, elle ne peut être tenue pour responsable de la mise à disposition de ces sites et des sources externes, et sa responsabilité ne pourra en aucun cas être engagée à raison du contenu, des publicités, des produits, des services ou de toute autre information ou donnée disponible sur ou à partir de ces sites ou sources externes. Il appartient à l'Utilisateur de procéder à toute vérification utile. De plus, MYMEDIKS ne pourra être tenue responsable de tous dommages ou pertes avérés ou allégués consécutifs ou en relation avec l'utilisation ou le fait d'avoir fait confiance au contenu, à des biens ou des services disponibles sur ces sites ou sources externes. Toute création de liens vers le Site, la Plateforme, l'Application et, de façon générale, toute utilisation d'un élément composant lesdits Site, la Plateforme, l'Application mobile sont soumis à l'autorisation préalable et expresse de MYMEDIKS, qui pourra être révoquée à tout moment à sa seule discrétion. MYMEDIKS se réserve le droit de (i) demander la suppression de tout lien vers le Site, la Plateforme, l'Application qui n'aurait pas été, ou ne serait plus, autorisé et de (ii) demander des dommages et intérêts en réparation du préjudice subi de ce fait.
            </p>
            <h3> 12.	PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL </h3>
            <p>
            MYMEDIKS accorde une grande importance à la protection des données à caractère personnel des Utilisateurs. 
            MYMEDIKS invite les Utilisateurs à consulter sa Politique de confidentialité qui détaille précisément les cas dans lesquels MYMEDIKS agit en qualité de responsable du traitement. 
            De manière générale, MYMEDIKS s’engage à prendre toutes les précautions utiles et toutes les mesures techniques et organisationnelles appropriées afin de garantir l’intégrité et la confidentialité des données à caractère personnel ainsi qu’un niveau de sécurité adapté au risque présenté par le traitement, et notamment, empêcher qu’elles soient détruites, perdues, altérées, conservées ou traitées d’une autre manière, divulguées de manière non autorisée ou que des tiers non autorisés y aient accès de manière accidentelle ou illicite. 
            MYMEDIKS a recours aux services d’un hébergeur agrée des données de santé à caractère personnel conformément aux dispositions de l’article L. 1111-8 du Code de la santé publique. 
            </p>
            <h3> 13.	COOKIES</h3>
            <p>
            MYMEDIKS invite expresséement l’Utilisateur à consulter sa politique relative aux Cookies qui fait partie intégrante des prsentes CGUV.
            </p>
            <h3> 14.	TRANSFERT DE DONNEES VERS L’APPLICATION</h3>
            <p>
            L’Utilisateur autorise expressément MYMEDIKS à procéder au transfert vers la Plateforme MYMEDIKS de toute Donnée Utilisateur,  nécessaire pour une parfaite utilisation des Services fournis par MYMEDIKS.
            </p>
            <h3> 15.	CONFIDENTIALITE </h3>
            <p>
            15.1 Hébergement des données de santé
            MYMEDIKS fournit des services aux Officines et aux Utilisateurs et ne collecte pour son propre compte aucune donnée concernant la santé des Utilisateurs. MYMEDIKS n’a accès à aucune donnée de santé des Utilisateurs. 
            Les Données renseignées et disponibles sur l’Interface MYMEDIKS et sur le Compte Utilisateur sont strictement confidentielles. Elles sont la propriété exclusive de l’Utilisateur.
            Sauf stipulation contraire, MYMEDIKS se réserve le droit de transférer les Données, d’un site d’hébergement à un autre, en France dans le strict respect de la réglementation applicable à l’Hébergement des Données de Santé. 
            L’hébergement des données et en particulier, l’Hébergement des Données de Santé est confié par MYMEDIKS à un Prestataire d’Hébergement des Données de Santé ayant obtenu la certification « Hébergeur de donnée de santé », conformément au décret d’application n° 2018-137 du 26 février 2018 relatif à l'hébergement de données de santé à caractère personnel. 
            MYMEDIKS déclare que le Prestataire d’Hébergement des Données de Santé est conforme aux normes applicables en la matière. 
            15.2 Durée de conservation
            Les Données Personnelles sont conservées en base active pendant 12 mois à compter du dernier contact avec l’Utilisateur, puis sont archivées selon une procédure d’archivage sécurisée pendant 12 mois.
            </p>

            <h3> 16.	NULLITÉ ET NON-RENONCIATION </h3>
            <p>
            Le fait, pour l'une des Parties, d’invoquer tardivement ou de ne pas invoquer un manquement de l'autre Partie ne saurait valoir, pour l'avenir, renonciation à invoquer le manquement en cause. 
            </p>
            <h3> 17.	SITES TIERS</h3>
            <p>
            Dans l’hypothèse où une clause contractuelle particulière serait nulle, illégale, ou inapplicable, la validité des autres dispositions des CGUV n’en serait aucunement affectée. 
            Pour le cas où les Site Internet, l’Application, l’Interface contiendraient des liens hypertextes renvoyant vers des sites Internet édités par des tiers (ci-après les « Sites Tiers ») sur lesquels MYMEDIKS n’exerce aucune sorte de contrôle, MYMEDIKS n’assume aucune responsabilité quant au contenu des Sites Tiers ou au contenu vers lequel les Sites Tiers peuvent renvoyer. 
            La présence de liens hypertextes vers des Sites Tiers ne sauraient signifier que MYMEDIKS approuve de quelque façon que ce soit les contenus des Sites Tiers. 
            MYMEDIKS n’est responsable d’aucune modification ou mise à jour concernant les Sites Tiers. MYMEDIKS n’est pas responsable de la transmission d’informations à partir des Sites Tiers, ni du mauvais fonctionnement de ceux-ci. 
            </p>
            <h3> 18.	FORCE MAJEURE</h3>
            <p> 
            Il y a force majeure lorsqu'un événement extérieur échappant au contrôle du débiteur de l’obligation, qui ne pouvait être raisonnablement prévu lors de la conclusion des CGUV et dont les effets ne peuvent être évités par des mesures appropriées, empêche l'exécution de son obligation par ledit débiteur. 
            Le cas de force majeure suspend les obligations de la partie concernée pendant le temps où jouera la force majeure si cet évènement est temporaire. Néanmoins, les Parties s’efforceront d’en minimiser dans toute la mesure du possible les conséquences. 
            A défaut, si l'empêchement est définitif, les parties seront libérées de leurs obligations dans les conditions prévues aux articles 1351 et 1351-1 du Code civil. 
            </p>
            <h3> 19.	LOI APPLICABLE ET ATTRIBUTION DE COMPÉTENCE </h3>
            <p>
            Les Parties conviennent que tout litige relatif à l’interprétation, à l’application, à la validité et à l’exécution du contrat est soumis à la loi française. 
            En cas de différend découlant de l’interprétation ou de l’exécution des présentes CGUV, ou plus largement en lien avec l’utilisation du Service et, en dehors de cas de non-respect des délais de règlement, l’Utilisateur et MYMEDIKS s’efforceront, préalablement à toute action judiciaire, de trouver une solution amiable à leur différend dans les meilleurs délais.

            En cas de d’échec du règlement à l’amiable du litige ; il sera soumis à la juridiction exclusive du Tribunal de Grande Instance de Paris, quel que soit le lieu d’exécution ou le domicile du défendeur, et ce même en cas d’appel en garantie, de pluralité de défendeurs, ou d’action en référé ou sur requête.

            En cas de rédaction des présentes conditions générales en plusieurs langues ou de traduction, seule la version française fera foi.
            </p>

            <h3> 20.	NOUS CONTACTER</h3>
            <p>
            MYMEDIKS met à la disposition de tous un service d’assistance téléphonique accessible au numéro suivant : 09 53 67 50 86 ou par courriel à l’adresse contact@my-mediks.com  
            Le service d’assistance téléphonique est ouvert tous les jours de 9h à 18h. 
            MYMEDIKS met également à disposition des Patients un Bot informatique pour lui permettre d’interagir avec les serveurs informatiques et de répondre à des questions récurrentes répertoriées par MYMEDIKS. 
            L’accès au service d’assistance est gratuit, à l’exception des éventuels coûts de communication Internet. 
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'cgu'
}
</script>

<style scoped>
    h3 {
        font-weight: bold;
        margin-bottom: 10px;
        margin-left: 24px;
    }
    p {
        margin-bottom: 24px;
    }
</style>