<template>
    <div class="mb-2 md:mb-4 xl:mb-6">
        <label v-if="label" :for="name" class="text-sm">{{ label }}</label>
        <input
            :type="type"
            @input="$emit('input', $event.target.value)"
            @change="$emit('change', $event.target.value)"
            @keyup.enter="$emit('keyupEnter')"
            :id="name"
            :name="name"
            :placeholder="placeholder"
            :value="phoneFormatted ? phoneFormattedValue : value"
            class="border-2 rounded-md block text-sm py-2 px-2 w-full focus:outline-none focus:border-primary"
            :class="getDynamicInputClasses + borderColorClass"
            :disabled="disabled"
        >
        <small v-if="small && errors === false" class="text-gray-400 italic">{{ small }}</small>
        <small v-if="errors || error" class="text-red-400 italic flex">{{ errors || error }}</small>
    </div>
</template>

<script>

    export default {
        props: {
            name: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: false,
            },
            placeholder: {
                type: String,
                default: "",
            },
            type: {
                type: String,
                default: 'text',
            },
            small: {
                type: String,
            },
            value: {},
            disabled: {
                type: Boolean,
                default: false,
            },
            error: {
                type: String,
                default: ''
            },
            phoneFormatted: {
                type: Boolean,
                default: false
            },
            // patient(green), pharmacist(yellow), deliverer(red)
            focusBorderColor: {
                type: String,
                default: 'patient'
            }
        },
        name: 'FormInput',
        data:() => ({
            errors: null,
        }),
        computed: {
            phoneFormattedValue() {
                if (!this.value) return this.value
                // add space each 2 character
                return this.value.replace(/[^\dA-Z]/g, '').replace(/(.{2})/g, '$1 ').trim()
            },
            getDynamicInputClasses() {
                let classes = '';
                if (this.errors || this.error) {
                    classes = "border-red-400"
                }
                return classes;
            },
            borderColorClass() {
                const className = {
                    patient: 'patient',
                    pharmacist: 'pharmacist',
                    deliverer: 'deliverer',
                    default: 'patient'
                }

                return className[this.focusBorderColor] || className.default
            }
        },
    };
</script>

<style scoped>
    small {
        margin-bottom: -1.2rem;
    }
    .patient:focus {
        border-color: #37e6a6;
    }
    .deliverer:focus {
        border-color: #FA7353;
    }
    .pharmacist:focus {
        border-color: #FFC41E;
    }
</style>