<template>
    <div class="contentDelivery">
        <div class="delivery">
            <div class="deliveryTitle">
                <h1 class="text-7xl">
                    Devenez coursier spécialisé mymediks
                </h1>
                <h2 class="inline-block text-lg">
                    Aidez-nous à être le meilleur service de livraison de médicaments 
                </h2>
                <a class="becomeDeliveryButton" target="_blank" @click="scrollMeNow('deliveryForm')">Devenez coursier</a>
            </div>

            <div class="arrowCircle">
            <a target="_blank"  @click="scrollMeToSection('sectionDelivery')"><img src="@/assets/img/down-arrow.svg"></a>
            </div>
        </div>

        <div class="mission" ref="sectionDelivery">
            <div class="missionTitle mb-6">
                <img src="../assets/img/orange-line.svg">
                <h3>Qui sommes-nous ?</h3>
            </div>
                
            <h4 class="text-5xl font-semibold">Notre mission</h4>
            
            <div class="ourMission font-normal">
                <p>Chez mymediks, notre mission est de<span> faciliter l'accès aux médicaments</span> pour tous
                et<span> d'accompagner les professionnels de santé</span> pour acheminer le médicament sur
                le dernier kilomètre, partout, à temps et en toute sécurité</p>
            </div>
            <div class="discover">
                <a class="clickButton cursor-pointer" target="_blank" @click="scrollMeNow('deliveryForm')">Devenez coursier</a>
            </div>
        </div>

        <div class="whyJoin">
            <div class="missionTitle">
                <img src="../assets/img/orange-line.svg">
                <h3>Pourquoi nous rejoindre ?</h3>
            </div>
            
            <h4 class="text-5xl inline-block font-semibold">Gérez vos revenus <br> à votre rythme, à vélo, scooter, <br> moto ou 4 roues</h4>
            
            <div class="joinText">
                <p class="font-light">
                    <span class="font-medium">
                        Connectez-vous sur notre application 
                    </span> 
                    lorsque vous êtes disponible
                    <br> Recevez des livraisons dans les zones où nous sommes présents pendant nos heures de service. 
                </p>
            </div>

            <div class="decide text-lg font-medium">
                <p>Vous décidez où et quand !</p>
            </div>
        </div>
        <div ref="deliveryForm">
            <LivreurForm/>
        </div>
    </div>
    
</template>

<script>
    import LivreurForm from "./../components/forms/Livreur"
    export default {
        name: "Livreur",
        components: {
            LivreurForm,
        },

        methods: {
            scrollMeToSection(sectionDelivery) {
                var element = this.$refs[sectionDelivery];
                var top = element.offsetTop;

                window.scrollTo({
                    top: top - 100,
                    behavior: 'smooth',
                });
            },

            scrollMeNow(deliveryForm) {
                var element = this.$refs[deliveryForm];
                var top = element.offsetTop;                

                window.scrollTo({
                    top: top + 10,
                    behavior: 'smooth',
                });
            }
        },
    };
</script>

<style>
    .contentDelivery {
        font-family: "Montserrat";
    }
    .deliveryTitle{
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 50%; 
        margin-left: 11rem;
        height: auto;
        margin-top: 25vh;
    }

    .delivery {
        height: 100vh;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .delivery::before {
        z-index: 0;
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url("../assets/img/Page devenir livreur.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right 55% top 20%;
        filter: brightness(0.6);
    }

    .delivery h1 {
        color: white;
        font-weight: bold;
        line-height: 5rem;
        margin-bottom: 3rem;
    }

    .delivery h2 {
        color: white;
    }

    .becomeDeliveryButton {
        display: flex;
        justify-content: center;
        width: 215px;
        font-weight: bold;
        color: white;
        background-color: #FA7353;
        border-radius: 29px;
        padding: 20px;
        margin: 3rem;
        margin-left: 0;
    }

    .arrowCircle {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: auto;
    }

    .mission {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 2rem;
        margin-top: 4rem;
        margin-bottom: 6rem;
        height: 80vh;
    }

    .missionTitle img {
        width: 5%;
        height: auto;
        padding-right: 1rem;
    }

    .missionTitle {
        padding-left: 10%;
        display: flex;
        flex-direction: row;
        color: #FA7353;
        font-weight: bold;
        font-size: 1rem;
    }

    .mission h4 {
        padding-left: 10%;
        color: black;
    }

    .ourMission {
        margin-top: 4rem;
    }

    .mission p {
        padding-left: 10%;
        color: black;
        font-size: 2rem;
        width: 80%;
    }

    .mission span {
        color: #FA7353;
    }

    .discover {
        display: flex;
        justify-content: center;
    }

    .clickButton {
        display: flex;
        justify-content: center;
        width: 190px;
        font-weight: bold;
        color: white;
        background-color: #FA7353;
        border-radius: 30px;
        padding: 20px;
        margin: 5rem;  
    }

    .whyJoin {
        background-color: #F5F6F6;
        background-image: url("../assets/img/main tel.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom 60% right;
        height: 100%;
        padding: 10% 0;
    }

    .whyJoin h4 {
        padding-left: 10%;
        color: black;
        margin-top: 2rem;
    }

    .joinText {
        padding-left: 10%;
        color: black;
        font-size: 1rem;
        margin-top: 2rem;
        width: 45%;
    }

    .joinText span {
        color: #FA7353;
        font-weight: bold;
    }

    .decide {
        padding-left: 10%;
        margin-top: 2rem;
        width: 45%;
    }

 @media screen and (max-width: 1200px) {
    .delivery {
        height: 100vh;
    }

    .whyJoin h4 {
        padding-left: 10%;
        color: black;
        font-weight: bold;
        margin-top: 2rem;
        line-height: 60px;
        width: 70%;
    }

        .whyJoin {
        background-image: url("../assets/img/main tel smaller.png");
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: bottom right;
        padding: none;
    }
 }

  @media screen and (max-width: 768px) {
    .delivery {
        height: 99vh;
    }

    .whyJoin h4 {
        padding-left: 10%;
        color: black;
        font-weight: bold;
        font-size: 3rem;
        margin-top: 2rem;
        line-height: 100%;
        width: 70%;
    }

    .whyJoin {
        background-image: url("../assets/img/main tel smaller.png");
        background-size: 90%;
        background-repeat: no-repeat;
        background-position: bottom right;
        padding: none;
    }
 }

</style>
