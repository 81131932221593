<template>
    <div>
        <div class="navHeader">

            <div class="flex justify-between w-full h-20 p-3">

                <div class="logo">
                    <router-link v-if="currentView === 'Pharmacien'" to="/" class="logomymedicks cursor-pointer">
                        <img class="logomymediks cursor-pointer" src="../assets/img/logo pro.svg">
                    </router-link>
                    <router-link v-else to="/" class="logomymedicks cursor-pointer">
                        <img class="logomymediks cursor-pointer" src="../assets/img/logo patient.svg">
                    </router-link>
                </div>

                <ul class="navbar-navlist font-semibold">
                    <router-link to="/" tag="li" class="nav-item cursor-pointer hidden lg:inline">
                        <span class="particulier">Me faire livrer</span>
                    </router-link>
                    <router-link to="/pharmacien" tag="li" class="nav-item cursor-pointer hidden lg:inline">
                        <span class="pharmacien">Vous êtes pharmacien ?</span>
                    </router-link>
                    <router-link to="/livreur" tag="li" class="nav-item cursor-pointer hidden lg:inline mr-12">
                        <span class="livreur">Devenir coursier</span>
                    </router-link>
                    <li class="nav-item cursor-pointer flex items-center mx-4 relative login-button"
                        @click="displayLoginDropdown = !displayLoginDropdown">
                        <img class="h-7 mr-1 login-button" :src="require('@/assets/img/contact.svg')">
                        <div class="flex flex-col login-button">
                            <div class="font-semibold text-base leading-5 login-button">Se connecter</div>
                            <div class="text-very-small login-button font-normal">Gérer mes commandes</div>
                        </div>

                        <transition name="fade">
                            <div v-show="displayLoginDropdown" v-click-outside="closeLoginDropdown"
                                 class="bg-white border shadow-xl absolute left-0 right-0 top-full login-dropdown rounded-md">
                                <div class="hover:bg-gray-200 p-3 cursor-pointer text-green-1"
                                     @click="redirect(patientUrl + '/login')">Patient
                                </div>
                                <div class="hover:bg-gray-200 p-3 cursor-pointer text-yellow-400"
                                     @click="redirect(pharmacistUrl + '/login')">Pharmacien
                                </div>
                            </div>
                        </transition>
                    </li>

                    <li class="nav-item lg:hidden" @click="isMenuOpen = !isMenuOpen">
                        <img class="block w-6 mr-4" src="../assets/icons/bars-solid.svg">
                    </li>
                </ul>
            </div>

            <transition name="fade">
                <div v-if="isMenuOpen" class="flex bg-white p-4">
                    <ul class="w-full">
                        <router-link to="/" tag="li" class="text-center text-green-1 font-bold text-xl pb-3">
                            <span>Me faire livrer</span>
                        </router-link>
                        <router-link to="/pharmacien" tag="li"
                                     class="text-center text-yellow-400 font-bold text-xl pb-3">
                            <span>Vous êtes pharmacien ?</span>
                        </router-link>
                        <router-link to="/livreur" tag="li" class="text-center text-red-400 font-bold text-xl pb-3">
                            <span>Devenir coursier</span>
                        </router-link>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
    props: {
        currentView: {}
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            isMenuOpen: false,
            displayLoginDropdown: false,
            patientUrl: process.env.VUE_APP_PATIENT_URL,
            pharmacistUrl: process.env.VUE_APP_PHARMACIST_URL
        }
    },
    methods: {
        redirect(url) {
            window.location = url
        },
        closeLoginDropdown(event) {
            if (this.displayLoginDropdown === true && !event.target.className.includes('login-button')) {
                this.displayLoginDropdown = false
            }
        }
    },
    goToPatient() {
        this.$router.push("/")
    },
};
</script>

<style lang="scss" scoped>
.text-very-small {
    font-size: 10px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.navHeader {
    z-index: 2;
    font-family: "Montserrat";
    position: fixed;
    height: 5rem;
    width: 100%;
    top: 0px;
    background: white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.particulier {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  color: white;
  background-color: #37e6a6;
  border-radius: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.pharmacien {
  font-size: 16px;
  color: #FFC41E;
  text-align: center;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.livreur {
    font-size: 16px;
    text-align: center;
}

.navbar-navlist {
    display: flex;
    align-items: center;
}

.loginButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    font-weight: bold;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 2rem;
    margin-right: 2rem;
}

.gestion {
    font-weight: lighter;
    margin-left: 20px;
    margin-top: -10px;
}

.logoTexte {
    display: flex;
    align-items: center;
}

.userlogo {
    padding: 8px;
    height: 35px;
    width: auto;
}

.logo img {
    display: inline-block;
    margin-left: 1.5rem;
    margin-top: 0.5rem;
    height: 80%;
    width: auto;
    margin-right: 10%;
}

@media screen and (max-width: 1024px) {

    .navHeader {
        width: 100%;
    }
    .particulier {
        width: 100%;
        height: 3rem;
        font-weight: bold;
        color: white;
        background-color: #37e6a6;
        border-radius: 30px;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .pharmacien {
        font-weight: bold;
        color: #FFC41E;
        text-align: center;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding-left: 1rem;
    }

    .livreur {
        font-weight: bold;
        color: #FF6B43;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .navHeader {
        width: 100%;
    }

    .navHeader {
        position: fixed;
    }

    .pharmacien {
        width: 120%;
        font-weight: bold;
        color: #FFC41E;
        text-align: center;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        padding-left: 1rem;
    }

    .loginButton {
        margin-left: -2rem;
        margin-right: 2rem;
    }
    .nav-item {
        // width: 20%;
        text-align: center;
    }
}


</style>
