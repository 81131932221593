<template>
    <div>
        <footer class="footer">
            <div class="footerleft">
                <a href="https://linkedin.com/company/mymediks" target="_blank">
                    <img class="linkedinimg" src="../assets/img/linkedin-logo.png" alt="linkedin">
                </a>

                <a href="https://www.facebook.com/mymediks-104296798614126" target="_blank">
                    <img class="linkedinimg rounded" src="../assets/img/facebook-logo.png" alt="linkedin">
                </a>
                <!-- <a href="#" target="_blank">Carrière</a> -->
                <a href="#" target="_blank" @click.prevent="goTo('/contact')">Contact</a>
                <!-- <a href="#" target="_blank">Aide</a> -->
            </div>
            <div class="footerRight">
                <a href="#" class="mx-2" target="_blank" @click.prevent="goTo('/mentions')">Mentions légales</a>
                <a href="#" class="mx-2" target="_blank" @click.prevent="goTo('/cgu')">CGU</a>
                <a href="#" class="mx-2" target="_blank" @click.prevent="goTo('/privacy-policy')">Politique de confidentialité</a>
                <a href="#" class="mx-2" target="_blank" @click.prevent="goTo('/cookies')">Cookies</a>
                <p class="mx-2">Copyright © 2021 Mymediks </p>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
      methods: {
            goTo(route) {
                this.$router.push(route)
            }
        },
    };
</script>

<style>
 .footer {
    font-family: "Montserrat";
    display: flex;
    justify-content: space-around;
    height: 10rem;
    margin-top: auto;
    background: #3E3E3E;
    position: relative;
 }

 .footerleft {
    font-size: 15px;
    font-weight: bold;
    color: #DBDDE4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
 }
 
 .linkedinimg {
     width: 30px;
     height: auto;
 }

 .footerRight {
    font-size: 13px;
    font-weight: bold;
    color: #DBDDE4;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 40%;
 }

 @media screen and (max-width: 1700px) {
    .footerRight {
        font-size: 12px;
        font-weight: 400;
    }
}

@media screen and (max-width: 1400px) {
    .footerRight {
        font-size: 10px;
    }
}
</style>
