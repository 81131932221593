<template>
    <div class="contact-page">
        <div class="contactUs">
            <!-- <div class="search">
                <h1>Comment pouvons-nous vous aider?</h1>
                    <input class="searchInput" type="email" id="mail" name="usermail" placeholder="Rechercher une question">
                    <p>Ou cliquez sur une catégorie qui pourrait vous orienter</p>
            </div>

            <div class="searchCategories">
                <div class="categorie-card">
                    <img class="categorie-join-img" src="./../assets/img/02_Icones_illustrations-56.png">
                    <p> Rejoindre Mymediks</p>
                </div>

                <div class="categorie-card">
                    <img class="categorie-join-img" src="./../assets/img/02_Icones_illustrations-57.png">
                    <p>Tarifs et facturation</p>
                </div>

                <div class="categorie-card">
                    <img class="categorie-join-img" src="./../assets/img/02_Icones_illustrations-58.png">
                    <p>Paramètre et compte</p>
                </div>

                <div class="categorie-card">
                    <img class="categorie-join-img" src="./../assets/img/02_Icones_illustrations-55.png">
                    <p>Guide d'utilisation</p>
                </div>

            </div> -->
        </div>
        <ContactForm/>
    </div>
</template>

<script>
    import ContactForm from "./../components/forms/Contact"
    export default {
        name: "Contact",
        components: {
            ContactForm,
        },
        mounted() {
            this.$parent.$data.currentView = "Contact"
        },
        methods: {
            goToPharma() {
                this.$router.push("/contact")
            }
        },
    };
</script>

<style> 
    .contact-page {
        min-height: 100vh;
        background-color: #F5F6F6;
    }
    .contactUs {
        font-family: "Montserrat";
        background-color: #F5F6F6;
        height: 100%;
        padding-top: 80px;
    }

    .contactUs h1 {
        color: black;
        font-weight: bold;
        line-height: 1rem;
        font-size: 300%;
        margin-bottom: 3rem;
    }

    .search {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10vh;
    }

    .searchInput {
        border: 2px solid #f1f1f1;
        border-radius: 15px;
        width: 40%;
        padding: 0.7rem;
        margin: 3rem;
    }

    .search p {
        color: #b8bbc2;
        font-weight: 500;
        margin: 2rem;
    }

    .searchCategories {
        display: flex;
        justify-content: center;
    }

    .categorie-card {
        background-color: white;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); 
        width: 15%;
        height: 10rem;
        margin: 1rem;
        margin-bottom: 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

     .categorie-card p {
        color: #4A4848;
        font-weight: 700;
     }

    .categorie-join-img {
        height: 60%;
        margin-top: 5%;
    }
</style>
