<template>
    <div id="app">
        <Header :current-view="currentView" />
        <transition name="page" mode="out-in">
            <router-view />
        </transition>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import {EventBus} from './main'

export default {
    components: {
        Header,
        Footer
    },
    data: ()=>({
        currentView: null,
    }),
    mounted() {
        EventBus.$on("cleanErrors", component => {
            component.errors = null;

            for (let ref in component.$refs) {
                component.$refs[ref].errors = null;
            }
        });

        EventBus.$on("setErrors", (component, errors) => {
            for (let error in errors) {
                if (component.$refs[error]) {
                    component.$refs[error].errors = errors[error][0];
                }
            }
        });
    },
    watch: {
        $route() {
            this.currentView = this.$route.name
            setTimeout(() =>{
                window.scrollTo({
                    top: 0,
            
                });
            },300)
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/main.scss";

    @font-face {
        font-family: "Montserrat";
        src: url("./assets/fonts/Montserrat-Light.otf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("./assets/fonts/Montserrat-Regular.otf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("./assets/fonts/Montserrat-Medium.otf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("./assets/fonts/Montserrat-SemiBold.otf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Montserrat";
        src: url("./assets/fonts/Montserrat-Bold.otf") format("truetype");
        font-weight: bold;
        font-style: normal;
    }


    #app {
        background: white;
        font-family:"Montserrat";
    }

    .page-enter-active,
    .page-leave-active {
        transition: all 0.3s ease;
    }

    .page-enter, .page-leave-to{
        opacity: 0;
        transform: translateY(50px);
    }

    .style-chooser {
        border: 2px #e5e7eb solid;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        padding-top: 10px; 
        padding-bottom: 10px; 

        .vs__dropdown-toggle {
            border: none;
            padding: 0;
        }
        .vs__dropdown-menu {
            left: -2px;
            margin-top: 0px;
            border:2px #e5e7eb solid;
            border-top: none;
            box-shadow: none;
            width: calc(100% + 4px);            
        }
        .vs__search,
        .vs__selected {
            font-size: 0.875rem;
            color: #A5A7AA;
            font-weight: 400;
            margin-top: 0; 
            font-family: "Montserrat", sans-serif;
        }

        .vs__search:focus {
            margin-top: 0;
        }

        .vs__selected {
            color: black;
        }
    }
</style>