<template>
	<div class="date-select w-full grid grid-cols-12 gap-2">

		<!-- Day -->
		<div class="col-span-3">
			<v-select
				v-model="selectedDay"
				:searchable="false"
				:clearable="false"
				name="days"
				id="days"
				:options="days"
				class="style-chooser w-full"
			/>
		</div>

		<!-- Month -->
		<div class="col-span-5">
			<v-select
				v-model="selectedMonth"
				:searchable="false"
				:clearable="false"
				name="months"
				id="months"
				:options="months"
				class="style-chooser w-full"
			/>
		</div>

		<!-- Year -->
		<div class="col-span-4">
			<v-select
				v-model="selectedYear"
				:searchable="false"
				:clearable="false"
				name="years"
				id="years"
				:options="years"
				class="style-chooser w-full"
			/>

		</div>

	</div>
</template>

<script>
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';
	const defaultMonths = {
		0: 'January',
		1: 'February',
		2: 'March',
		3: 'April',
		4: 'May',
		5: 'June',
		6: 'July',
		7: 'August',
		8: 'September',
		9: 'October',
		10: 'November',
		11: 'December'
	}

	export default {
		name: "DateDropdown",
		components: {
			vSelect
		},
		props: {
			default: {
				type: String,
				required: false
			},
			min: {
				type: String,
				required: false
			},
			max: {
				type: String,
				required: false
			},
			monthsNames: {
				type: String,
				required: false
			}
		},

		data () {
			return {
				days: [],
				selectedDay: '',
				selectedMonth: '',
				selectedYear: ''
			}
		},

		computed: {
			// Returns if there are default date settings.
			initialDate() {
				return !! (this.default || this.min)
			},

			// The date property, converted to a date.
			specifiedDate() {
                let date = new Date('January 01, 1950 00:00:00');
                date.setDate(this.default.split('/')[0])
                date.setMonth(this.default.split('/')[1])
                date.setFullYear(this.default.split('/')[2])
				return date
			},

			// The minimum date the will allow user to select.
			minDate() {
				if (this.min) {
                    return new Date(this.min)
                }
				return new Date('1900')
			},

			// The maximum date the will allow user to select.
			maxDate() {
				if (this.max) {
					return new Date(this.max)
                }

				return new Date()
			},

			// Returns a formatted date e.g. 01.02.2018.
			calculatedDate() {
				const day = this.selectedDay >= 10 ? this.selectedDay : `0${this.selectedDay}`
				const month = this.selectedMonth + 1 >= 10 ? this.selectedMonth + 1 : `0${this.selectedMonth + 1}`

				return `${day}.${month}.${this.selectedYear}`
			},

			// The alternative names of months
			dividedNamesOfMonths() {
				if (this.monthsNames)
					return this.monthsNames.replace(/\s/g, '').split(',')

				return "Janvier, Février, Mars, Avril, Mai, Juin, Juillet, Aout, Septembre, Octobre, Novembre, Décembre"
			},

			// Computes a list of month.
			months() {
				let months = []

				for (let i = 0; i < 12; i++) {
					if (this.dividedNamesOfMonths)
						months.push(this.dividedNamesOfMonths[i])
					else
						months.push(defaultMonths[i])
				}

				return months.map((month, i) => {
					return { label: month, code: this.addZeroOnUnit(i+1) }
				})
			},

			// Computes a list of years.
			years() {
				// Set the first year of the array.
				let firstYear

				if (this.min) {
					firstYear = this.minDate.getFullYear()
				} else if (this.default) {
					firstYear = this.specifiedDate.getFullYear()
				} else {
					firstYear = (new Date).getFullYear()
				}

				// Create a range of years to loop through which is either the maximum
				// date minus the first year, or simply 100.
				let through = this.max ? (this.maxDate.getFullYear() + 1) - firstYear : 101
				let years = []

				for (let i = firstYear, len = firstYear + through; i < len; i++)
					years.push(i)

				return years.map((year) => {
					return year
				})
			}
		},

		methods: {
			addZeroOnUnit(number) {
				if (number.toString().length === 1) {
					return '0' + number
				}

				return number
			},
			getDays() {
				let days = []
				let daysNumberOfMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate()

				for (let i = 1; i < daysNumberOfMonth + 1; i++)
					days.push(i)

				return days.map((day) => {
					return this.addZeroOnUnit(day)
				})
			},

			updateDays() {
				this.days = this.getDays()
			},

			// Send data to upper component.
			sendDate() {
				const date = `${this.selectedDay}.${this.selectedMonth.code}.${this.selectedYear}`

				this.$emit('input', date)
			},

			// Set the initial date.
			setDate() {
				this.updateDays()

				// If a value property has been passed to the component, that will be
				// used instead of the current day.
				let date

				if (this.min && this.max && !this.default) {
					date = new Date(this.min)
				} else if (this.default) {
                    date = new Date('January 01, 1950 00:00:00');
                    date.setDate(this.default.split('/')[0])
                    date.setMonth(this.default.split('/')[1])
                    date.setFullYear(this.default.split('/')[2])
				} else {
					date = new Date()
				}

				// if (this.initialDate)
				// 	this.selectedDay = date.getDate() + 1
				// else
				// 	this.selectedDay = date.getDate()

				this.selectedDay = this.addZeroOnUnit(date.getDate())
				this.selectedMonth = this.months[date.getMonth()]
				this.selectedYear = date.getFullYear()

				this.sendDate()
			}
		},

		created() {
			// Set the date when the component was created
			this.setDate()
		},

		updated() {
			// Send the data on change
			this.sendDate()
		},
	}
</script>

<style lang="scss">
	.date-select {
		.style-chooser .vs__search, .style-chooser .vs__selected {
			color: black;
			font-size: 0.875rem;
			font-weight: 400;
		}

		.style-chooser .vs__dropdown-menu {
			min-width: 0;
		}
	}
</style>