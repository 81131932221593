<template>
    <div>
        <div class="flex flex-col">
            <label
                class="font-bold text-md"
            >Mot de passe</label>
            <input
                class="border-2 rounded-md block text-sm py-2 px-2 w-full focus:outline-none focus:border-primary"
                type="password"
                :placeholder="placeholder"
                @input="checkPasswordVerifications"
                v-model="password"
                @keyup.enter="$emit('keyupEnter')"
            >
            <label
                class="font-bold text-md mt-2"
            >Confirmez votre mot de passe</label>
            <input
                type="password"
                class="border-2 rounded-md block text-sm py-2 px-2 w-full focus:outline-none focus:border-primary"
                placeholder="Confirmez votre mot de passe"
                @input="checkValidPasswordBeforeSending"
                v-model="password_confirmed"
                @keyup.enter="$emit('keyupEnter')"
            >
            <div class="flex flex-col mt-2">
                <small
                    :class="{ 
                        'orange': validation === true && passwordConfirmation === false 
                    }"
                    class="mb-1 text-gray-1"
                >
                <transition name="check-transition">
                    <span class="text-green-1" v-if="passwordConfirmation"> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                    <span v-else> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                </transition>
                Les deux mot de passes doivent être identiques</small>
                <small
                    class="mb-1 text-gray-1"
                    :class="{ 
                        'orange': validation === true && passwordVerifications.length === false 
                    }"
                >
                <transition name="check-transition">
                    <span class="text-green-1" v-if="passwordVerifications.length"> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                    <span v-else> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                </transition>
                Votre mot de passe doit comporter au moins 6 caractères.</small>
                <div class="flex">
                    <div class="flex flex-col md:w-1/2 w-1/3">
                        <small
                            class="my-1 text-gray-1"
                            :class="{ 
                                'orange': validation === true && passwordVerifications.uppercase === false 
                            }"
                        >   
                            <transition name="check-transition">
                                <span class="text-green-1" v-if="passwordVerifications.uppercase"> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                                <span v-else> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                            </transition>
                            1 Majuscule
                        </small>
                        <small
                            class="text-gray-1"
                            :class="{ 
                                'orange': validation === true && passwordVerifications.lowercase === false 
                            }"
                        >
                            <transition name="check-transition">
                                <span class="text-green-1" v-if="passwordVerifications.lowercase"> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                                <span v-else> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                            </transition>
                            1 Minuscule
                        </small>
                    </div>
                    <div class="flex flex-col md:w-1/2">
                        <small
                            class="my-1 text-gray-1"
                            :class="{ 
                                'orange': validation === true && passwordVerifications.number === false 
                            }"
                        >
                            <transition name="check-transition">
                                <span class="text-green-1" v-if="passwordVerifications.number"> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                                <span v-else> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                            </transition>
                            1 Chiffre
                        </small>
                        <small
                            class="text-gray-1"
                            :class="{ 
                                'orange': validation === true && passwordVerifications.special === false 
                            }"
                        >
                            <transition name="check-transition">
                                <span class="text-green-1" v-if="passwordVerifications.special"> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                                <span v-else> <CheckCircleSolid class="w-3.5 inline-block"></CheckCircleSolid> </span>
                            </transition>
                            1 Caratère spécial (!@#$^&*)
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckCircleSolid from "@/assets/icons/check-circle-solid";
    export default {
        name: 'CheckPassword',
        props: {
            placeholder: {
                type: String,
                required: false,
            }
        },
        components: {
            CheckCircleSolid
        },
        data:() => ({
            passwordVerifications: {
                length: false,
                uppercase: false,
                lowercase: false,
                number: false,
                special: false,
            },
            password: null,
            password_confirmed: null,
            password_error: false,
            validation: false
        }),
        methods: {
            checkPasswordVerifications(event) {
                let value = event.target.value

                let lengthRegex = "(?=.{6,})"
                let uppercaseRegex = "(?=.*[A-Z])"
                let lowercaseRegex = "(?=.*[a-z])"
                let numberRegex = "(?=.*[0-9])"
                let specialRegex = "(?=.*[!@#$%^&*])"

                this.passwordVerifications.length = false
                this.passwordVerifications.uppercase = false
                this.passwordVerifications.lowercase = false
                this.passwordVerifications.number = false
                this.passwordVerifications.special = false

                if (new RegExp(lengthRegex).test(value)) {
                    this.passwordVerifications.length = true
                }
                if (new RegExp(uppercaseRegex).test(value)) {
                    this.passwordVerifications.uppercase = true
                }
                if (new RegExp(lowercaseRegex).test(value)) {
                    this.passwordVerifications.lowercase = true
                }
                if (new RegExp(numberRegex).test(value)) {
                    this.passwordVerifications.number = true
                }
                if (new RegExp(specialRegex).test(value)) {
                    this.passwordVerifications.special = true
                }

                this.checkValidPasswordBeforeSending()
                this.$emit('input', this.password)
                this.$emit('changePassword', this.password)
            },
            checkValidPasswordBeforeSending() {
                let isValid = true

                for (let rule in this.passwordVerifications) {
                    if (!this.passwordVerifications[rule]) {
                        isValid = false
                        break
                    }
                }
                
                this.$emit('changeConfirmationPassword', this.password_confirmed)
                this.$emit('passwordCheck', isValid && this.passwordConfirmation)
            },
        },
        computed: {
            passwordConfirmation() {
                return (this.password === this.password_confirmed) && this.password !== null
            }
        }
    };
</script>

<style scoped>
.orange{
    color: #FF6B43;
}

.check-transition-enter-active {
    transition: all 0.3s ease-in;
}
.check-transition-leave-active {
    transition: all 0.3s ease-out;
}
.check-transition-enter, .check-transition-leave-to{
	opacity: 0;
    transform: scale(0.5);
}

</style>
