<template>
    <div class="relative">
        <label v-if="label" :for="name" class="text-sm">{{ label }}</label>
        <input
            :id="name"
            :name="name"
            :placeholder="placeholder"
            type="text"
            class="border-2 rounded-md block text-sm py-2 px-2 w-full focus:outline-none focus:border-primary"
            @input="search"
            v-model="fullAddressSelected"
            :class="getDynamicInputClasses + borderColorClass"
            @click="search"
            autocomplete="none"
        >
        <transition name="fade" mode="out-in">
            <small v-if="errors || error" class="text-red-400 italic flex">{{ errors || error }}</small>
        </transition>

        <transition name="fade">
            <div v-if="addresses.length > 0" class="resultPropalAdress absolute w-full bg-white rounded-b-md -mt-1 border-gray-200 border-2 z-10">
                <ul v-click-outside="closeAddresses">
                    <li @click="selectAddress(address)"
                        class="text-sm p-2 pl-4 cursor-pointer font-medium hover:bg-gray-100"
                        v-for="address in addresses"
                        :key="address.properties.id"
                    >
                        {{address.properties.label}}
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
    import axios from 'axios';
    import vClickOutside from 'v-click-outside'

    export default {
        name: 'ApiAddress',
        props: {
            name: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                required: false,
            },
            placeholder: {
                type: String,
                required: true,
            },
            value: {
                type: String,
                required: false,
            },
            error: {
                type: String,
                default: '',
            },
            // patient(green), pharmacist(yellow), deliverer(red)
            focusBorderColor: {
                type: String,
                default: 'patient'
            }
        },
        data:() => ({
            addresses: [],
            address: {
                id: null,
                address: null,
                city: null,
                cp: null,
                coords: null,
            },
            fullAddressSelected: null,
            errors: null,
        }),
        directives: {
            clickOutside: vClickOutside.directive
        },
        mounted() {
            if (this.value) {
                this.fullAddressSelected = this.value
            }
        },
        methods: {
            search(el) {
                let querySearch = el.target.value
                if (querySearch.length >= 6) {
                    axios.get('https://api-adresse.data.gouv.fr/search/?q=' + querySearch)
                    .then(response => {
                        this.addresses = response.data.features
                    })
                }
            },
            selectAddress(addressObject) {
                // Définition des attributs de l'adresse
                this.address.id = addressObject.properties.id
                this.address.address = addressObject.properties.name
                this.address.city = addressObject.properties.city
                this.address.cp = addressObject.properties.postcode
                this.address.coords = addressObject.geometry.coordinates
                this.address.label = addressObject.properties.label
                // Défini du champ address complet
                this.fullAddressSelected = addressObject.properties.label
                // On emit vers le parent puis on ferme la modal des adresses
                this.$emit('defineAddress', this.address)
                this.closeAddresses()
            },
            closeAddresses() {
                this.addresses = []
            }
        },
        computed: {
            getDynamicInputClasses() {
                let classes = '';
                if (this.errors || this.error) {
                    classes = "border-red-400"
                }
                return classes;
            },
            borderColorClass() {
                const className = {
                    patient: 'patient',
                    pharmacist: 'pharmacist',
                    deliverer: 'deliverer',
                    default: 'patient'
                }

                return className[this.focusBorderColor] || className.default
            }
        },
    };
</script>

<style scoped>
    .patient:focus {
        border-color: #37e6a6;
    }
    .deliverer:focus {
        border-color: #FA7353;
    }
    .pharmacist:focus {
        border-color: #FFC41E;
    }
</style>